// New dashboard theme color
$theme_color: #fdf399;
$primary-black: #1a1e22;
$primary-white: #fff;
$light_gray: #aeaeae;
$dark_gray: #767676;
$light_blue: #9bf5f5;
$light_yellow: #e3faa2;
$dark-yellow: #fdf399;
$light_pink: #edc6ed;
$graph_color: #fed101;
$hover_color: #fafafa;
$link_color: #19acff;
$box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);

$light-white: rgb(251, 251, 251);

@font-face {
  font-family: "Ginto Nord";
  src: url(../../public/font/GintoNord-Regular.ttf);
}

@font-face {
  font-family: "Maison Nue Ext";
  src: url(../../public/font/MaisonNeueExt-Medium.ttf);
}

$font-family: "Maison Nue Ext", sans-serif;
.ant-picker .ant-picker-suffix {
  display: none;
}

.ant-pagination-options {
  display: none !important;
}

* {
  box-sizing: border-box;
  font-family: $font-family;
}

::-webkit-scrollbar {
  display: none;
}
html {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
}
body {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-family: $font-family;
  line-height: 1.5;
  overflow-x: hidden;
  background-color: $theme_color;
}
a {
  text-decoration: none;
  transition: all 0.5s;
}
img {
  vertical-align: top;
  max-width: 100%;
}
h1,
h2,
h3,
h4,
h5,
h6,
a {
  font-family: "Ginto Nord";
}
p {
  font-family: $font-family;
  margin: 0 0 15px;
  &:last-child {
    margin-bottom: 0;
  }
}
.color-black {
  color: $primary-black;
}
.bg-yellow {
  background-color: $theme_color !important;
}
.bottom-border {
  border-bottom: 1px solid $dark_gray;
}
.margin-top {
  margin-top: 20px !important;
}
.margin-bottom {
  margin-bottom: 20px !important;
}
input {
  &::placeholder {
    opacity: 1;
    color: $dark_gray;
  }
  &::-moz-placeholder {
    opacity: 1;
    color: $dark_gray;
  }
  &:hover {
    border-color: $primary-black !important;
    box-shadow: none !important;
  }
  &:focus {
    border-color: $primary-black !important;
    box-shadow: none !important;
  }
}
.ant-form-item-control-input-content {
  display: flex;
  align-items: center;
  gap: 5px;
  .ant-input-suffix {
    font-size: 20px;
  }
}
.ant-input-data-count{
  bottom: -32px;
  font-size: 15px;
}

.ant-tooltip .ant-tooltip-inner {
  min-width: "max-content";
  @media only screen and (max-width: 1299px) {
    min-width: "max-content" !important;
  }
  @media only screen and (max-width: 992px) {
    min-width: "max-content" !important;
    font-size: 10px !important;
  }
}
:where(.css-ed5zg0).ant-input-affix-wrapper:not(
    .ant-input-affix-wrapper-disabled
  ):hover {
  border-color: $primary-black !important;
  box-shadow: none !important;
}
:where(.css-ed5zg0).ant-input-number-affix-wrapper-focused {
  border-color: $primary-black !important;
  box-shadow: none !important;
}
:where(.css-ed5zg0).ant-input-number-affix-wrapper:not(
    .ant-input-number-affix-wrapper-disabled
  ):hover {
  border-color: $primary-black !important;
  box-shadow: none !important;
}
:where(.css-ed5zg0).ant-select:not(.ant-select-disabled):not(
    .ant-select-customize-input
  ):not(.ant-pagination-size-changer):hover
  .ant-select-selector {
  border-color: $primary-black !important;
  box-shadow: none !important;
}
.ant-input-affix-wrapper-focused {
  border-color: $primary-black !important;
  box-shadow: none !important;
}
.ant-select-focused {
  .ant-select-selector {
    border: 1px solid $primary-black !important;
    box-shadow: none !important;
  }
  .anticon-close-circle {
    display: block !important;
  }
}
:where(.css-ed5zg0).ant-input-affix-wrapper:not(
    .ant-input-affix-wrapper-disabled
  ):hover {
  border-color: $primary-black !important;
  box-shadow: none !important;
}
:where(.css-ed5zg0).ant-input-affix-wrapper:hover {
  border-color: $primary-black !important;
  box-shadow: none !important;
}
:where(.css-ed5zg0).ant-input-textarea-affix-wrapper.ant-input-affix-wrapper
  > textarea.ant-input {
  font-weight: 500 !important;
  color: $primary-black !important;
}

:where(.css-ed5zg0).ant-input-disabled:hover,
:where(.css-ed5zg0).ant-input[disabled]:hover {
  border-color: #e5e5e5 !important;
}

:where(.css-ed5zg0).ant-select:not(.ant-select-disabled):not(
    .ant-select-customize-input
  ):not(.ant-pagination-size-changer):hover
  .ant-select-selector {
  border-color: $primary-black !important;
  box-shadow: none !important;
}
.ant-input {
  font-family: $font-family;
}
.ant-form-item {
  margin-bottom: 12px !important;
}
.ant-form-item-explain-error {
  margin-bottom: 10px;
}
.container {
  width: 100%;
  max-width: 1260px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.btn {
  border: 1px solid $primary-black;
  border-radius: 7px;
  font-weight: 500;
  font-size: 16px;
  line-height: normal;
  color: $primary-black;
  padding: 8px 20px;
  cursor: pointer;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  display: inline-block;
  vertical-align: top;
  &:hover {
    background-color: $primary-black;
    color: $primary-white;
  }
  @media only screen and (max-width: 768px) {
    padding: 8px 25px;
    font-size: 16px;
  }
}
.primary_btn.btn {
  background-color: $primary-white;
  color: $primary-black;
  border: 1px solid $primary-black;
  cursor: pointer;
  border-radius: 7px !important;
  &:hover {
    background-color: #464646;
    color: $primary-white;
  }
}

// ant date picker selected css
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-start
  .ant-picker-cell-inner,
.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-end
  .ant-picker-cell-inner {
  background-color: $primary-black !important;
}
.ant-picker-cell-inner::before {
  border: 1px solid $primary-black !important;
}

// ant checkbox css

.ant-checkbox {
  top: 0 !important;
}
.ant-checkbox-wrapper {
  margin: 0 !important;
  align-items: center !important;
}

.ant-checkbox-inner {
  height: 18px !important;
  width: 18px !important;
  border-radius: 0 !important;
  border: 1px solid $primary-black !important;
}

.ant-checkbox-disabled .ant-checkbox-inner:after {
  border-color: $primary-white !important;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: none !important;
}

.ant-checkbox-checked::after {
  border: 1px solid $primary-black !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: $primary-black !important;
  border: 1px solid $primary-black !important;
}
.ant-modal {
  max-width: calc(100% - 100px);
}
.ant-modal-si {
  width: 600px !important;
}
.ant-modal-mask {
  backdrop-filter: blur(10px);
}
.field-error {
  color: #ff4d4f;
  margin-top: -15px;
  font-size: 14px;
}
.register-error {
  color: #ff4d4f;
  height: 25px;
  margin-top: -14px;
}

.resetPassword-error {
  color: #ff4d4f;
  margin-top: -30px;
}

.ant-collapse {
  background-color: rgb(255, 255, 255);
  border: none;
  .ant-collapse-item {
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }
}

// ant common css
.ant-select-open,
.ant-select-focused {
  .ant-select-selector {
    border: 1px solid $primary-black !important;
    box-shadow: none !important;
  }
  .anticon-close-circle {
    display: block !important;
  }
}
.ant-select-selector,
.ant-picker {
  border-radius: 100px;
  background: $theme_color !important;
  padding: 11px 16px !important;
  height: 43px !important;
  border: 0.5px solid $dark_gray !important;
  border-radius: 30px !important;
  align-items: center;
  gap: 16px;

  .ant-select-selection-search,
  .ant-select-selection-search-input {
    height: 43px !important;
    font-weight: 500 !important;
  }
  .ant-select-selection-placeholder,
  input::placeholder {
    color: $dark_gray;
    font-family: $font-family;
    font-weight: 500;
  }
  .ant-select-selection-item {
    font-weight: 500 !important;
  }
  .ant-picker-clear {
    opacity: 1;
    background: transparent;
    color: $primary-black;
  }
  .anticon-close-circle {
    display: block !important;
  }
}
.ant-select-selection-placeholder,
input::placeholder {
  color: $primary-black;
  font-family: $font-family;
  font-weight: 500;
}

.ant-modal-confirm-body{
  justify-content: center;
  align-items: center;
}
.anticon-close-circle {
  display: none;
}
.session-Model {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .session-modal-logo {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 30px;
    .bd-logo {
      border: 1px solid;
      border-radius: 50%;
      border-color: #ff6163;
      height: 77px;
      width: 77px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      svg {
        font-size: 70px;
        color: #ff6163;
      }
    }
  }
  .session-modal-text {
    text-align: center;
    h3 {
      margin-bottom: 0;
      font-size: 30px;
    }
    p {
      font-size: 16px;
    }
  }
  .session-modal-btn {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 35px;
  }
}
.check {
  display: none;
}
.btn-flex {
  display: flex;
  gap: 5px;
  align-items: center;
}

.dashboard-content-container {
  width: calc(100% - 280px);
  margin-left: 280px;
  overflow: scroll;
  @media screen and (max-width: 1025px) {
    width: calc(100% - 240px);
    margin-left: 240px;
  }
  @media screen and (max-width: 992px) {
    width: calc(100% - 200px);
    margin-left: 200px;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    margin-left: 0;
    position: relative;
    margin-top: 82.88px;
  }
}
.dashboard-sidebar-container {
  height: 100vh;
  width: 280px;
  position: fixed;
  background-color: $theme_color;
  flex-direction: column;
  display: flex;
  overflow: scroll;
  @media screen and (max-width: 1025px) {
    width: 240px;
  }
  @media screen and (max-width: 992px) {
    width: 200px;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    z-index: 99;
    box-shadow: rgba(255, 255, 255, 0.24) 0px 0px 2px 0px,
      rgba(48, 48, 48, 0.24) 0px 10px 25px -4px;
    overflow: visible;
    height: auto;
  }
  .dashboard-top {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (max-width: 768px) {
      flex-direction: row;
      padding: 6px 20px;
    }
    .desktop-menu-icon {
      @media screen and (min-width: 769px) {
        display: none;
      }
      .inline-svg {
        display: block;
        margin: -7px auto 0;
        width: 50px;
        height: 36px;
        .svg-menu-toggle {
          fill: #000;
          pointer-events: all;
          cursor: pointer;
          .bar {
            -webkit-transform: rotate(0) translateY(0) translateX(0);
            transform: rotate(0) translateY(0) translateX(0);
            opacity: 1;
            -webkit-transform-origin: 20px 10px;
            transform-origin: 20px 10px;
            -webkit-transition: -webkit-transform 0.4s ease-in-out,
              opacity 0.2s ease-in-out;
            transition: transform 0.4s ease-in-out, opacity 0.2s ease-in-out;
          }
          .bar:nth-of-type(1) {
            -webkit-transform-origin: 20px 10px;
            transform-origin: 20px 10px;
          }
          .bar:nth-of-type(3) {
            -webkit-transform-origin: 20px 20px;
            transform-origin: 20px 20px;
          }
        }
      }
      &.active {
        .inline-svg {
          .svg-menu-toggle {
            .bar:nth-of-type(1) {
              -webkit-transform: rotate(-45deg) translateY(0) translateX(0);
              transform: rotate(-45deg) translateY(0) translateX(0);
            }
            .bar:nth-of-type(2) {
              opacity: 0;
            }
            .bar:nth-of-type(3) {
              -webkit-transform: rotate(45deg) translateY(0em) translateX(0em);
              transform: rotate(45deg) translateY(0em) translateX(0em);
            }
          }
        }
      }
    }
    .dashboard-logo {
      @media screen and (max-width: 768px) {
        flex: 1 1 0%;
        margin-left: 5px;
      }
      a {
        @media screen and (max-width: 768px) {
          display: inline-block;
          vertical-align: top;
        }
      }
      svg {
        @media screen and (max-width: 768px) {
          width: 48px !important;
          height: auto !important;
        }
      }
    }
    .store-name {
      padding: 10px 30px;
      background: white;
      width: 100%;
      text-align: center;
      @media screen and (max-width: 768px) {
        width: auto;
        border-radius: 100px;
        background: #000;
        color: #ffffff;
        display: inline-block;
        vertical-align: top;
        line-height: normal;
      }
      @media screen and (max-width: 481px) {
        padding: 10px 20px;
      }
    }
  }
  .dashboard-option {
    display: flex;
    flex-direction: column;
    height: -webkit-fill-available;
    gap: 20px;
    @media screen and (max-width: 768px) {
      width: 200px;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 999;
      margin-top: 82.88px;
      height: calc(100vh - 82.88px);
      overflow: scroll;
      background: #fdf399;
      transition: all 0.4s ease-in-out;
      margin-left: -200px;
    }
    &.active {
      margin-left: 0;
    }
    .dashboard-menu {
      display: flex;
      flex-direction: column;
      padding: 10px 20px 20px;
      margin: 0 auto;
      border-bottom: 1px solid black;
      gap: 5px;
      @media screen and (max-width: 992px) {
        padding: 10px 6px 20px;
      }
      .single-option {
        padding: 7px 30px;
        display: flex;
        align-items: center;
        color: $primary-black;
        font-size: 16px;
        border-radius: 100px;
        font-weight: bold;
        cursor: pointer;
        width: 100%;
        justify-content: flex-start;
        border: 1px solid transparent;
        @media screen and (max-width: 992px) {
          padding: 7px 10px;
        }
        .option-icon {
          margin-right: 10px;
          display: flex;
          & + div {
            line-height: 26px;
            display: inline-block;
            vertical-align: top;
            font-weight: 500;
          }
        }
      }
      .single-selected-option {
        align-items: center;
        border-radius: 30px;
        cursor: pointer;
        display: flex;
        font-size: 16px;
        padding: 5px 30px;
        position: relative;
        cursor: pointer;
        border: 1px solid $primary-black;
        width: 100%;
        justify-content: flex-start;
        @media screen and (max-width: 992px) {
          padding: 5px 10px;
        }
        .option-icon {
          margin-right: 10px;
          display: flex;
          & + div {
            line-height: 26px;
            display: inline-block;
            vertical-align: top;
            font-weight: 500;
          }
        }
      }
    }
    .logout-option {
      display: flex;
      gap: 12px;
      padding: 20px;
      flex-flow: column;
      align-items: center;
      margin: 0 auto;
      min-width: 84%;
      @media screen and (max-width: 992px) {
        padding: 20px 6px;
      }
      .profile-detail {
        text-align: center;
        display: flex;
        flex-flow: column;
        font-weight: 500;
        padding-bottom: 10px;
        border-bottom: 1px solid black;
      }
      .logout-button {
        display: flex;
        font-size: 16px;
        cursor: pointer;
        gap: 10px;
        width: 100%;
        justify-content: center;
        border: 1px solid black;
        border-radius: 30px;
        padding: 5px 30px;
        @media screen and (max-width: 992px) {
          padding: 5px 10px;
        }
      }
    }
    .account-manager-container {
      width: 100%;
      display: flex;
      justify-content: center;
      .main-contaier {
        background-color: white;
        width: 90%;
        padding: 16px;
        border-radius: 12px;
        .account-title {
          padding-bottom: 3px;
          color: #000000;
        }
        .child-container {
          display: flex;
          align-items: center;
          gap: 12px;
          margin-top: 14px;
          .profile-img {
            padding: 10px;
            background-color: #fdf399;
            border-radius: 50%;
            font-weight: bold;
            .profile-img {
              width: 100%;
            }
          }
          .profile-name {
            width: 180px;
            span {
              font-size: 16px;
              color: #000000;
              font-weight: 600;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              overflow: hidden;
            }
            p {
              word-break: break-all;
              font-size: 12px;
              color: #686868;
            }
          }
        }
        .contact-btn {
          border-radius: 11px;
          background: #4f4f4f;
          display: block;
          width: 100%;
          color: #fff;
          text-align: center;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 14px;
          padding: 12px 15px;
          border: none;
          margin-top: 14px;
        }
      }
    }
  }
}
.dashboard-header-container {
  width: "100%";
  width: "100%";
  display: flex;
  z-index: 10;
  .dashboard-header-main {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 7px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 20px;
    .label-title {
      font-weight: 600;
      font-size: 24px;
      margin: 0;
      font-weight: bold;
      display: flex;
      align-items: center;
      gap: 10px;
    }
    .current-time {
      font-size: 13px;
      color: $light_gray;
    }
  }
}
.ant-dropdown {
  ul {
    li:last-child {
      &:hover {
        background-color: white !important;
      }
    }
  }
}

//Register Page
.register-header {
  display: flex;
  justify-content: space-between;
  background: $primary-white;
  align-items: center;
  .logo {
    width: 50%;
    padding: 20px 0 20px 30px;
    background: #fbfbfb;
    img {
      width: 180px;
    }
  }
  .help-section {
    width: 50%;
    display: flex;
    gap: 20px;
    padding: 20px 30px 20px 20px;
    justify-content: flex-end;
    .link {
      color: #ebd400;
      cursor: pointer;
    }
  }
}
.register-page-container {
  width: 100%;
  height: calc(100vh - 97px);
  display: flex;
  align-items: center;
  position: relative;

  .register-left-side {
    width: 50%;
    background-image: url("./../assets/images/registerBackground.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 100%;
    display: flex;
    align-items: center;
    color: $primary-white;
    justify-content: center;

    .data-container {
      margin: auto 20px;
      text-align: center;
      padding: 10px;
      width: 450px;
      color: $primary-black;
      padding-bottom: 30px;

      .title {
        font-size: 22px;
        font-weight: 600;
        line-height: 28px;
        margin-bottom: 20px;
        text-transform: uppercase;
      }
      .description {
        font-size: 13px;
        line-height: 20px;
        margin-bottom: 24px;
        text-transform: capitalize;
        font-weight: 500;
      }
      .statics-data {
        font-size: 15px;
        margin-top: 24px;

        .number-data {
          font-size: 22px;
          font-weight: 700;
          line-height: 28px;
          .counter-name {
            text-transform: capitalize;
          }
        }
      }
    }
  }

  .register-right-side {
    width: 50%;
    height: 100%;
    background: #fbfbfb;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;

    .register-form-section {
      height: 100%;
      width: 100%;
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;

      .register-title {
        font-size: 28px;
        padding-top: 10px;
        color: $primary-black;
        font-weight: bold;
        text-transform: capitalize;
        text-align: center;
      }
      .register-form-view {
        max-width: 600px;
        width: 100%;
        padding: 10px;
        border-radius: 10px;
        .form-input {
          width: 380px;
          padding: 10px;

          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }

          & {
            -moz-appearance: textfield;
          }
        }
        .name-email {
          flex-direction: row;
          display: flex;
          gap: 10px;
          .form-input-name-email {
            width: 185px;
            padding: 10px;
          }
        }
      }
      .register-termCond {
        font-size: 12px;
        margin-top: 10px;
        text-align: center;
        width: 380px;
        .link {
          color: #ebd400;
          cursor: pointer;
        }
      }
    }

    .register-button {
      width: 100%;
      display: flex;
      justify-content: center;
      padding: 20px 0;
      background: $primary-white;
      box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
      .btn {
        color: #eeeeee;
        cursor: pointer;
        background-color: $primary-black;
        border-radius: 20px;
        border: none;
      }
    }
  }
  .ant-input,
  .form-input {
    border-color: $primary-black !important;
    border-radius: 4px !important;
    background-color: $primary-white;
  }
  .btn {
    border-radius: 7px;
  }
}

//Login Page

.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  .login-page-container {
    display: flex;
    background-color: #fdf399;
    position: relative;
    flex-flow: column;
    align-items: center;
    padding: 30px;
    border-radius: 75px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);

    @media screen and (max-width: 768px) {
      max-width: 380px;
    }

    .page-title {
      text-align: center;
      .logo {
        width: 180px;
        margin: 0 auto;
        img {
          width: 250px;
          @media screen and (max-width: 768px) {
            width: 300px;
            height: auto;
          }
          @media screen and (max-width: 576px) {
            width: 220px;
            height: auto;
          }
        }
        @media screen and (max-width: 1025px) {
          padding: 15px 0;
        }
      }
      .user-role {
        font-size: 30px;
        font-weight: 700;
        @media screen and (max-width: 576px) {
          font-size: 24px;
        }
      }
    }

    .login-right-side {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .login-title {
        font-size: 28px;
        font-weight: 500;
        font-family: $font-family;
        margin-bottom: 30px;
        @media screen and (max-width: 576px) {
          font-size: 24px;
        }
      }
      .login-form {
        .login-form-input {
          display: flex;
          gap: 20px;
          flex-direction: column;
          svg {
            font-size: 24px;
          }
          input {
            width: 320px;
            outline: none;
            font-size: 16px;
            height: 35px;
            background-color: transparent !important;
            box-shadow: none;
            transition: all 0.4s ease;
          }
          .login-password {
            margin-top: -10px !important;
          }
          .login-forget {
            text-align: right;
            color: $primary-black;
            cursor: pointer;
            margin-top: -27px;
            font-size: 15px;
            font-weight: 500;
          }
        }
        .login-button {
          margin-top: 15px;
          button {
            width: 100%;
            background-color: $primary-black;
            color: $primary-white;
            padding: 13px 0;
            cursor: pointer;
            border: none;
            &:hover {
              background-color: #1c1c1c;
              color: $primary-white;
              cursor: pointer;
            }
          }
          &.reset-password-btn {
            margin-bottom: 20px;
          }
        }
        @media screen and (max-width: 576px) {
          width: 340px;
        }
      }
      .account {
        margin-top: 15px;
        cursor: pointer;
      }
      .login-termCond {
        width: 380px;
        text-align: center;
        margin-top: 30px;
        font-size: 14px;
        @media screen and (max-width: 576px) {
          margin-top: 0px;
        }
        a {
          color: $primary-black;
          font-weight: 700;
          text-decoration: underline;
        }
      }

      .sign-in {
        text-align: right;
        color: $primary-black;
        cursor: pointer;
        margin-top: 15px;
        font-size: 15px;
        font-weight: 500;
      }
      .forgot-text {
        width: 262px;
        text-align: center;
        font-size: 14px;
        margin-bottom: 30px;
      }
      .ant-input-affix-wrapper {
        border-color: $primary-black !important;
        border-radius: 4px !important;
        @media screen and (max-width: 768px) {
          max-width: 320px !important;
        }
      }
      .primary_btn.btn {
        border-radius: 7px !important;
      }
    }

    @media only screen and (max-width: 1025px) {
    }
    @media only screen and (max-width: 992px) {
      .login-right-side {
        width: 100%;
      }
    }
  }

  #background-video {
    width: 100vw;
    height: 100vh;
    object-fit: initial;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
  }
}

.invalid-token-model {
  display: flex;
  text-align: center;
  flex-direction: column;
  .invalid-token-model-title {
    font-size: 30px;
    font-weight: 600;
  }
  .invalid-token-model-desc {
    margin-top: 20px;
    font-size: 20px;
    margin-bottom: 30px;
  }
}

.message-modal {
  text-align: center;
  .warning-image {
    height: 100px;
    width: 100px;
  }
  .modal-message {
    font-size: 18px;
    padding: 20px 0;
  }
}
// DashBoard-OnBoard
.onBoard-main {
  display: flex;
  min-height: 100vh;
  background-color: $theme_color;
  align-items: center;
  padding: 20px 20px 20px 0;
  flex-direction: row;
  @media screen and (max-width: 768px) {
    padding: 20px;
  }
  .onBoard-container {
    display: flex;
    min-height: 94vh;
    background-color: $primary-white;
    border-radius: 20px;
    width: -webkit-fill-available;
    flex-direction: column;
    padding: 20px;
    flex: 0 0 100%;
    max-width: 100%;
    .onBoard-main-section {
      margin-top: 20px;
      .ant-select-arrow,
      .ant-select-clear {
        color: $primary-black;
        inset-inline-end: 20px;
      }
      .onBoard-well-back-section {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
        .onBoard-back-button {
          a {
            color: $primary-black;
            cursor: pointer;
            text-decoration: underline;
            font-size: 15px;
          }
        }
        .onBoard-welcome {
          font-weight: bold;
          font-size: 20px;
        }
      }
      .box-view {
        border-radius: 10px;
        margin-top: 20px;
        background-color: $primary-white;
        .claim-title {
          font-weight: bold;
          font-size: 16px;
          color: $primary-black;
        }
        .box-section {
          grid-template-columns: repeat(4, minmax(0px, 1fr));
          box-sizing: border-box;
          display: grid;
          gap: 24px;
          width: 100%;
          .first-section {
            padding: 20px;
            box-shadow: $box-shadow;
            border-radius: 15px;
            min-height: 83px;
            .content-view {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: space-around;
              width: 100%;
              h3 {
                font-size: 12px;
                margin: 0;
                color: $light_gray;
              }
              p {
                color: $primary-black;
                font-size: 20px;
              }
              .icon-view {
                height: 52px;
                width: 52px;
                border-radius: 7px;
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }
          }
          @media screen and (max-width: 1200px) {
            grid-template-columns: repeat(2, minmax(0px, 1fr));
          }
        }
      }
      .merchant-dashboard-bottom {
        margin-top: 20px;
        display: flex;
        gap: 20px;
        .task-section {
          width: 35%;
          .task-box {
            background: $primary-white;
            box-shadow: $box-shadow;
            border-radius: 7px;
            min-height: 350px;
            padding: 30px;
            .task-title {
              font-weight: bold;
              font-size: 18px;
              padding: 0 0 25px 0;
            }
            .task-contain {
              display: flex;
              justify-content: space-between;
              margin-top: 10px;
              gap: 40px;
              margin-left: 10px;
              .task-name {
                font-size: 14px;
                font-weight: 500;
                margin: 0;
              }
              .task-desc {
                font-size: 12px;
                color: $light_gray;
                margin-top: 7px;
                text-align: justify;
                word-spacing: -1px;
              }
              .task-link {
                text-align: end;
                min-width: fit-content;
                a {
                  color: $link_color;
                  font-size: 12px;
                  font-weight: 500;
                }
              }
              @media screen and (max-width: 1200px) {
                display: block;
              }
            }
            .order-dots {
              width: 30px;
              height: 30px;
              background-color: #ececec;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              &.done {
                background-color: $theme_color;
              }
            }
          }
          @media screen and (max-width: 1200px) {
            width: 50%;
          }
        }
        .analytics-section {
          width: 100%;
          .merchant-graph-container {
            display: flex;
            flex-direction: row;
            gap: 20px;
            width: 100%;
            .revenue-chart {
              background-color: #fff;
              border-radius: 10px;
              box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
              min-height: 200px;
              padding: 20px;
              width: 33%;
              display: grid;
              .revenue-chart-title {
                color: #1a1e22;
                font-size: 16px;
                font-weight: 700;
              }
              &.taskDone-width {
                width: 35%;
              }
              .no-record-found {
                text-align: center;
                color: $light_gray;
              }
            }
            .merchant-new-claim {
              background-color: #fff;
              border-radius: 10px;
              box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
              padding: 20px;
              width: 40%;
              // display: grid;
              min-height: 200px;

              .new-claim-title {
                color: #1a1e22;
                font-size: 16px;
                font-weight: 700;
              }
              .recent-activities-container {
                max-height: 300px;
                overflow: scroll;
                .claim-content-view {
                  padding: 7px;
                  display: flex;
                  gap: 15px;
                  align-items: center;
                  justify-content: space-between;
                  .claim-view {
                    display: flex;
                    gap: 15px;
                    max-width: 80%;
                    .claim-data {
                      display: flex;
                      flex-direction: column-reverse;
                      justify-content: space-between;
                      margin-bottom: 5px;
                      gap: 1px;
                      max-width: 65%;

                      .claim-data-id {
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        font-size: 13px;
                        color: $light_gray;
                        &.id {
                          color: #263238;
                          cursor: pointer;
                          font-weight: 600;
                          font-size: 15px;
                        }
                      }
                      .claim-label {
                        font-size: 13px;
                        .order-name {
                          color: #005dd7;
                        }
                      }
                    }
                    .claim-profile {
                      img {
                        height: 44px;
                        max-width: 44px;
                      }
                    }
                  }
                  &:hover {
                    background-color: #fdf399;
                    transition: 0.5s ease all;
                    border-radius: 12px;
                  }

                  .right-arrow-icon {
                    img {
                      height: 35px;
                      max-width: 35px;
                    }
                  }
                }
                .no-record-found {
                  text-align: center;
                  color: $light_gray;
                }
              }
              &.taskDone-width {
                width: 35%;
              }
            }
            @media screen and (max-width: 1200px) {
              flex-wrap: wrap;
              .revenue-chart {
                width: calc(50% - 10px);
                &.taskDone-width {
                  width: 100%;
                }
              }
              .merchant-new-claim {
                width: 100%;
                &.taskDone-width {
                  width: 100%;
                }
              }
            }
            @media screen and (max-width: 768px) {
              flex-wrap: wrap;
              .revenue-chart {
                width: 100%;
                &.taskDone-width {
                  width: 100%;
                }
              }
              .merchant-new-claim {
                width: 100%;
                &.taskDone-width {
                  width: 100%;
                }
              }
            }
          }
          &.fullWidth {
            width: 100%;
          }
          .spline-graph {
            background-color: #fff;
            border-radius: 10px;
            box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
            min-height: 200px;
            padding: 20px;
            width: 33%;
            display: grid;
            .spline-chart-title {
              color: #1a1e22;
              font-size: 16px;
              font-weight: 700;
            }
            &.setWidth {
              width: 60%;
              margin-top: 0;
            }
            .no-record-found {
              text-align: center;
              color: $light_gray;
            }
            @media screen and (max-width: 1200px) {
              &.setWidth {
                width: 100%;
                margin-top: 0;
              }
            }
          }
          @media screen and (max-width: 1200px) {
            width: 100%;
          }
        }
      }
      .data-range {
        display: flex;
        gap: 30px;
        margin: 30px 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        padding: 18px 0;
        align-items: center;
        .date-range {
          text-align: center;
          .ant-picker {
            input {
              width: 110px !important;
              &::placeholder {
                color: $primary-black !important;
              }
            }
          }
        }
        .info-text {
          font-size: 14px;
        }
        @media screen and (max-width: 768px) {
          flex-flow: column;
        }
      }
      .data-container-track {
        margin-top: 80px;
        @media screen and (max-width: 1200px) {
          margin-top: 60px;
        }
        @media screen and (max-width: 768px) {
          margin-top: 40px;
        }
      }
      .data-container-protect {
        margin-bottom: 20px;
        .data-container-title {
          //margin-top: 80px;
          margin-bottom: -30px;
          // @media screen and (max-width: 768px) {
          //   margin-top: 60px;
          // }
          // @media screen and (max-width: 576px) {
          //   margin-top: 40px;
          // }
          .block {
            display: inline-block;
            width: 7px;
            height: 25px;
            background-color: $theme_color;
            margin-right: 17px;
          }
          .text {
            font-weight: 700;
            font-size: 25px;
            line-height: 72px;
            color: $primary-black;
          }
        }
        .data-container {
          display: flex;
          margin-top: 32px;
          gap: 30px;
          justify-content: space-between;
          @media screen and (max-width: 641px) {
            flex-wrap: wrap;
          }
          .data-container-box {
            flex: 0 0 auto;
            width: 30%;
            @media screen and (max-width: 641px) {
              width: 100%;
            }
            .data-container-box-border {
              margin-bottom: 12px;
              height: 4px;
              width: 0px;
              background: $theme_color;
              animation: forwards-content 0.8s cubic-bezier(0.45, 0, 0.55, 1) 0s
                1 normal forwards running;

              @keyframes forwards-content {
                0% {
                  width: 0px;
                }
                100% {
                  width: 100%;
                }
              }
            }

            .data-container-box-number-data {
              margin-top: 12px;
              margin-bottom: 12px;
              overflow: hidden;
              position: relative;
              font-weight: 700;
              font-size: 33px;
              line-height: 48px;
              &.visible {
                transform: translate(0px, -50px);
                animation: 0.8s cubic-bezier(0.65, 0, 0.35, 1) 0s 1 normal
                  forwards running animation-1a82qgd;

                @keyframes animation-1a82qgd {
                  0% {
                    transform: translate(0px, -50px);
                  }
                  100% {
                    transform: translate(0px, 0px);
                  }
                }
              }
            }

            .data-container-box-title-data {
              margin-bottom: 16px;
              overflow: hidden;
              font-weight: 600;
              font-size: 14px;
              line-height: 16px;
              &.visible {
                transform: translate(0px, -20px);
                animation: 0.8s cubic-bezier(0.33, 1, 0.68, 1) 0.14s 1 normal
                  forwards running animation-9yp3vi;
                @keyframes animation-9yp3vi {
                  0% {
                    transform: translate(0px, -20px);
                  }
                  100% {
                    transform: translate(0px, 0px);
                  }
                }
              }
            }

            .data-container-box-description {
              font-weight: 400;
              font-size: 14px;
              line-height: 19px;
              color: rgba(0, 0, 0, 0.6);
              transform: translate(0px, -20px);
              opacity: 0;
              animation: 0.8s cubic-bezier(0.33, 1, 0.68, 1) 0.22s 1 normal
                forwards running animation-1lu0f27;

              @keyframes animation-1lu0f27 {
                0% {
                  transform: translate(0px, -20px);
                  opacity: 0;
                }
                100% {
                  transform: translate(0px, 0px);
                  opacity: 1;
                }
              }
            }
          }
        }
      }

      .mg-bottom {
        margin-bottom: 32px;
      }
    }
  }
}

// claim status view css for single merchant and multiple merchant in store detail
.revenue-detail-main {
  .revenue-detail-title {
    border-left: 8px solid $theme_color;
    margin-bottom: 40px;
    span {
      margin-left: 10px;
      font-size: 20px;
      font-weight: bold;
    }
  }
  .box-section {
    grid-template-columns: repeat(4, minmax(0px, 1fr));
    box-sizing: border-box;
    display: grid;
    gap: 24px;
    width: 100%;
    .first-section {
      padding: 22px;
      box-shadow: $box-shadow;
      border-radius: 15px;
      min-height: 83px;
      .content-view {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        width: 100%;
        h3 {
          font-size: 12px;
          margin: 0;
          color: $light_gray;
        }
        p {
          color: $primary-black;
          font-size: 20px;
        }
        .icon-view {
          height: 52px;
          width: 52px;
          border-radius: 7px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    @media screen and (max-width: 1200px) {
      grid-template-columns: repeat(2, minmax(0px, 1fr));
    }
    @media screen and (max-width: 481px) {
      grid-template-columns: repeat(1, minmax(0px, 1fr));
    }
  }
  .revenue-chart-section {
    display: flex;
    margin-top: 30px;
    gap: 20px;
    @media screen and (max-width: 992px) {
      flex-direction: column;
    }
    .chart-box {
      background-color: $primary-white;
      border-radius: 10px;
      min-height: 330px;
      box-shadow: $box-shadow;
      padding: 10px;
      display: grid;
      .chart-box-content {
        padding: 10px;
        display: grid;

        p {
          font-weight: bold;
          font-size: 16px;
          color: $primary-black;
        }
        .no-record-found {
          text-align: center;
          color: $light_gray;
        }
      }
    }
    .revenue-chart {
      width: 40%;
      @media screen and (max-width: 1367px) {
        width: 50%;
      }
      @media screen and (max-width: 992px) {
        width: 100%;
      }
    }
    .month-revenue-chart {
      width: 60%;
      @media screen and (max-width: 992px) {
        width: 100%;
      }
    }
  }
}
.task {
  display: flex;
  width: 100%;
  .left-side {
    width: 30%;
    background: #fdf399;
    padding: 10px 0;
    display: flex;
    flex-wrap: wrap;
    gap: 200px;
    justify-content: center;
    min-height: 100vh;
    position: relative;
    .top-logo {
      padding: 100px 10px;
    }
    .bottom-logo {
      display: flex;
      justify-content: center;
      z-index: 2;
      position: absolute;
      bottom: 68px;
      svg {
        width: 80%;
        height: 100%;
      }
      @media screen and (max-width: 1025px) {
        position: absolute;
        bottom: 92px;
      }
    }
    &::after {
      content: "";
      width: 100%;
      height: 145px;
      background: #ede175;
      position: absolute;
      z-index: 1;
      bottom: 0;
    }
  }
  .right-side {
    width: 70%;
    .onBoard-main-section-step {
      margin-top: 10%;
      .ant-select-selector,
      .ant-picker {
        border-radius: 100px;
        background: #f5f5f5 !important;
        padding: 5px 20px !important;
        height: 43px !important;
        border: 1px solid transparent !important;
        border-radius: 100px !important;
        .ant-select-selection-search,
        .ant-select-selection-search-input {
          height: 43px !important;
          font-weight: 500 !important;
        }
        .ant-select-selection-placeholder,
        input::placeholder {
          color: $primary-black;
          font-family: $font-family;
          font-weight: 500;
        }
        .ant-select-selection-item {
          font-weight: 500 !important;
        }
      }
      .ant-select-arrow,
      .ant-select-clear {
        color: $primary-black;
        inset-inline-end: 20px;
      }
      .onBoard-well-back {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: center;
        .onBoard-hid {
          width: 100%;
          display: flex;
          flex-flow: column;
          justify-content: center;
          text-align: center;
        }
        .onBoard-back-button {
          width: 25%;
          a {
            color: $primary-black;
            cursor: pointer;
            font-weight: 500;
            text-decoration: underline;
          }
        }
        .onBoard-welcome {
          text-align: center;
          width: 50%;
          h2 {
            margin: 0;
          }
        }
      }
    }
    .onBoard-body-title-step {
      margin-bottom: 20px;
      text-align: center;
    }
    .onBoard-body-desc-step {
      align-items: center;
      display: flex;
      flex-direction: column;
      gap: 15px;
      margin: 0 20px;
      text-align: center;
      .step-content {
        display: flex;
        flex-flow: column;
        align-items: center;
        gap: 10px;
        min-height: 160px;
        @media screen and (max-width: 1300px) {
          min-height: 210px;
        }
      }
      .RSPBprogressBar {
        min-width: 40%;
      }

      div {
        display: flex;
        font-weight: 500;
        strong {
          color: $primary-black;
          font-weight: 700;
          text-decoration: underline;
          cursor: pointer;
        }
      }
      .button-container {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        // gap: 300px;
        .btn-step {
          cursor: pointer;
          display: flex;
          align-items: center;
          gap: 6px;
        }
        .btn-right {
          margin-left: 300px;
        }
        .btn-finish {
          cursor: pointer;
          color: $primary-white;
          background: $primary-black;
          padding: 5px 10px;
        }
      }
    }
  }
}

.onBoard-body {
  .onBoard-box {
    width: 100%;
    margin: 30px auto;
    background-color: $primary-white;
    color: rgb(33, 43, 54);
    background-image: none;
    overflow: hidden;
    box-shadow: $box-shadow;
    border-radius: 10px;
    &.gap-box {
      padding: 24px;
    }
    .onBoard-body-title {
      margin-bottom: 20px;
    }
    .onBoard-body-desc {
      display: flex;
      flex-direction: column;
      gap: 15px;
      @keyframes slideIn {
        from {
          transform: translateY(100%);
          opacity: 0;
        }
        to {
          transform: translateY(0);
          opacity: 1;
        }
      }

      .slide-in {
        animation: slideIn 0.8s ease-in-out;
      }
      div {
        display: flex;
        color: $primary-black;
        font-weight: 500;
        strong {
          color: $primary-black;
          font-weight: 700;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }

    .overview-statement {
      .credit-table {
        margin-top: 20px;
        .table-title {
          padding: 10px;
          font-size: 18px;
          font-weight: 600;
        }
      }
      .usage-table {
        .table-title {
          padding: 10px;
          font-size: 18px;
          font-weight: 600;
        }
      }
      .overview-title {
        padding: 10px;
        font-size: 22px;
        font-weight: 600;
        background-color: $theme_color;
        border-radius: 7px 7px 0 0;
      }
      .overview-data {
        display: flex;
        flex-wrap: wrap;
        padding: 40px 0;
        background-color: #f1f1f1;
        border-radius: 0 0 7px 7px;
        p {
          width: 280px;
          padding: 0 20px;
          border-right: 1px solid grey;
          margin-right: 10px;
          font-weight: 600;
          &:last-child {
            border-right: none !important;
          }
          span {
            color: grey;
            font-weight: 500;
          }
        }
      }
    }
  }
}
.onBoard-Model {
  .onBoard-title {
    font-family: $font-family;
    font-size: 20px;
    font-weight: 600;
  }
  .onBoard-button {
    display: flex;
    justify-content: flex-end;
    .primary_btn {
      padding: 8px 25px;
      font-size: 13px;
    }
  }
}
.claim-main {
  display: flex;
  min-height: 100vh;
  background-color: $theme_color;
  align-items: center;
  padding: 20px 20px 20px 0;
  flex-direction: row;
  @media screen and (max-width: 768px) {
    padding: 20px;
  }
  .claim-container {
    display: flex;
    min-height: 94vh;
    background-color: $primary-white;
    border-radius: 20px;
    width: -webkit-fill-available;
    flex-direction: column;
    padding: 20px;
    flex: 0 0 100%;
    max-width: 100%;
    .claim-main-section {
      margin-top: 20px;
      .claim-Head {
        .claim-d {
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-wrap: wrap;
          gap: 15px;
          .claim-head-button {
            button {
              background: black;
              display: flex;
              align-items: center;
              font-size: 21px;
              gap: 5px;
              padding: 0px 20px;
              border-radius: 7px !important;
              color: white;
              border: 2px solid;
              width: 155px;
              @media screen and (max-width: 992px) {
              }
              &:hover {
                background-color: #464646 !important;
                border: 2px solid;
                border-color: #1a1e22;
              }
            }
          }
          .claim-filters {
            display: flex;
            align-items: center;
            gap: 10px;
            @media screen and (max-width: 1199px) {
              flex-wrap: wrap;
            }
            @media only screen and (max-width: 576px) {
              .ant-select {
                width: 100% !important;
              }
              .ant-picker {
                width: 100% !important;
              }
            }
            .ant-select-arrow,
            .ant-picker-clear,
            .ant-select-clear {
              color: $primary-black;
              inset-inline-end: 20px;
            }
            .ant-picker-active-bar {
              background: $primary-black;
            }
            //search input box
            .ant-input-group-wrapper {
              background-color: $theme_color;
              padding: 0px 0px 0px 0px;
              border-radius: 25px;
              border: 1px solid $dark_gray;
              max-width: 300px;
              @media only screen and (max-width: 576px) {
                max-width: 100%;
              }
              .ant-input-wrapper,
              .ant-input {
                border: none;
                padding: 0px;
                padding: 5px 10px;
                background-color: transparent;
                .ant-btn {
                  background-color: transparent;
                  border: none;
                }
                .ant-input-group-addon .ant-btn {
                  cursor: auto;
                  background-color: black;
                  border-radius: 20px !important;
                  margin-right: -1px;
                  padding: 6px 12px;
                }
              }
            }
          }
        }
      }
      .claim-body {
        .claim-box {
          width: 100%;
          margin-top: 30px;
          background-color: $primary-white;
          color: rgb(33, 43, 54);
          transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
          background-image: none;
          overflow: auto;
          box-shadow: rgba(145, 158, 171, 0.24) 0px 0px 2px 0px,
            rgba(145, 158, 171, 0.24) 0px 16px 32px -4px;
          border-radius: 10px;
          .ant-table-tbody {
            tr {
              cursor: pointer;
            }
          }
        }
      }
      .store-page-filter {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 10px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        padding: 0 0 20px 0;
        @media screen and (max-width: 768px) {
          label {
            width: 100%;
          }
        }
        .or-text {
          padding: 0 10px;
          @media screen and (max-width: 768px) {
            width: 100%;
            padding-left: 0;
          }
        }
        .ant-select-arrow,
        .ant-select-clear {
          color: $primary-black;
          inset-inline-end: 20px;
        }
        @media screen and (max-width: 768px) {
          .ant-select {
            width: 100% !important;
          }
          .ant-picker {
            width: 100% !important;
          }
        }
      }
    }
  }
}

.ant-table-tbody {
  tr {
    cursor: pointer;
  }
}
.create-claim-body {
  margin: 8px auto;
  span {
    width: 100%;
    font-size: 12px;
    font-weight: 500;
    color: #8b8989;
  }
}
.create-claim-searched {
  padding: 10px;
  margin: 8px auto;
  max-height: 315px;
  overflow: auto;
  .create-claim-searched-inner {
    a {
      color: $primary-black;
    }
  }
}
.create-claim-searched-order {
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  border: 2px solid;
  border-radius: 10px;
  font-weight: 500;
  .create-claim-search-id {
    width: 50%;
    border-bottom: 1px solid;
  }
  .create-claim-search-fav {
    width: 50%;
    text-align: end;
    border-bottom: 1px solid;
  }
  .create-claim-search-email {
    width: 60%;
  }
  .create-claim-search-link {
    width: 40%;
    text-align: end;
    p {
      text-decoration: underline;
      cursor: pointer;
      color: #008000;
    }
  }
}

.no-record-found {
  color: $dark_gray;
  font-size: 18px;
  text-align: center;
}

.claim-info {
  .claim-info-title {
    margin-top: 30px;
  }
  .claim-info-inputs {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    .error {
      margin-top: 5px;
    }
    label {
      margin: 10px 0 5px;
    }
    input {
      outline: none;
      font-size: 12px;
      padding: 0 20px;
      height: 40px;
      background-color: transparent !important;
      border: 1px solid #ddd;
      border-radius: 7px;
      box-shadow: 0 0 transparent;
      transition: all 0.4s ease;
      box-shadow: none;
    }
    .ant-select > input,
    .ant-select-selector {
      outline: none;
      width: 100%;
      font-size: 14px;
      padding: 0 20px;
      height: 43px !important;
      background-color: transparent !important;
      border: 1px solid #ddd;
      border-radius: 7px;
      box-shadow: 0 0 transparent;
      transition: all 0.4s ease;
      box-shadow: none span {
        padding: 2px;
      }
    }
    .ant-textarea {
      outline: none;
      width: 100%;
      height: 90px;
      font-size: 14px;
      padding: 15px 20px;
      resize: none;
      vertical-align: text-top;
      background-color: transparent !important;
      border: 1px solid #ddd;
      border-radius: 7px;
      box-shadow: 0 0 transparent;
      transition: all 0.4s ease;
      box-shadow: none;
    }
  }
  .submit-back-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 22px;
    .btn {
      font-size: 14px;
      line-height: 15px;
      padding: 7px 15px;
    }
  }
}

.detail-box {
  .note-button {
    text-align: end;
    margin-top: 10px;
    button {
      border-radius: 100px !important;
      background-color: $theme_color;
      font-size: 13px;
      &:hover {
        color: $primary-black;
        background-color: $primary-white;
      }
    }
  }
}

.create-admin-claim-body {
  margin: 8px auto;
  display: flex;
  gap: 10px;
  flex-direction: column;
  .ant-select-selector,
  .ant-picker {
    background: $light-white !important;
    padding: 11px 16px !important;
    height: 41px !important;
    border: 0.5px solid $dark_gray !important;
    border-radius: 7px !important;
    align-items: center;
    gap: 16px;
  }
}
.ReorderInput {
  font-weight: 600;
}
// Admin Claim page

.admin-claim-list-main {
  display: flex;
  min-height: 100vh;
  background-color: $theme_color;
  align-items: center;
  padding: 20px 20px 20px 0;
  flex-direction: row;
  @media screen and (max-width: 768px) {
    padding: 20px 20px;
  }
  .admin-claim-list-container {
    display: flex;
    min-height: 94vh;
    background-color: $primary-white;
    border-radius: 20px;
    width: -webkit-fill-available;
    flex-direction: column;
    padding: 20px;
    flex: 0 0 100%;
    max-width: 100%;
    .Admin-claim-page {
      width: 100%;
      margin-top: 30px;
      .admin-claim-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 24px;
        @media screen and (max-width: 1480px) {
          flex-wrap: wrap;
          gap: 15px;
        }
        .claim-head-button {
          margin-left: 15px;
          @media screen and (max-width: 1480px) {
            margin-left: auto;
          }
        }
        button {
          background: black;
          display: flex;
          align-items: center;
          font-size: 21px;
          gap: 5px;
          padding: 0px 20px;
          border-radius: 7px !important;
          color: white;
          border: 2px solid;
          &:hover {
            background-color: #464646 !important;
            border: 2px solid;
            border-color: #1a1e22;
          }
        }
        //search input box
        .ant-input-group-wrapper {
          background-color: $theme_color;
          padding: 0px 0px 0px 0px;
          border-radius: 25px;
          border: 1px solid $dark_gray;
          max-width: 300px;
          @media only screen and (max-width: 576px) {
            max-width: 100%
          }
          .ant-input-wrapper,
          .ant-input {
            border: none;
            padding: 0px;
            padding: 5px 10px;
            background-color: transparent;
            .ant-btn {
              background-color: transparent;
              border: none;
            }
            .ant-input-group-addon .ant-btn {
              cursor: auto;
              background-color: black;
              border-radius: 20px !important;
              margin-right: -1px;
              padding: 12px;
            }
          }
        }
      }
      .merchant-list {
        display: flex;
        gap: 30px;
        @media screen and (max-width: 992px) {
          gap: 15px;
          justify-content: space-between;
          width: 100%;
        }
        @media screen and (max-width: 1199px) {
          flex-wrap: wrap;
        }
        @media only screen and (max-width: 576px) {
          .ant-select {
            width: 100% !important;
          }
          .ant-picker {
            width: 100% !important;
          }
        }
        .ant-picker-clear {
          opacity: 1 !important;
        }
        .ant-select-arrow,
        .ant-picker-clear,
        .ant-select-clear {
          color: $primary-black;
          inset-inline-end: 20px;
        }
        .ant-picker-active-bar {
          background: $primary-black;
        }
      }
      .admin-claim-table {
        width: 100%;
        margin-top: 30px;
        color: $primary-black;
        transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        background-image: none;
        overflow: auto;
        box-shadow: rgb(255 255 255 / 24%) 0px 0px 2px 0px,
          rgb(48 48 48 / 24%) 0px 10px 25px -4px;
        border-radius: 10px;
        background: $primary-white;
        .record-found {
          color: #808080;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin: 0 0 15px 0;
          p {
            font-size: 18px;
            font-weight: 600;
            color: $primary-black;
            margin: 0;
          }
          span {
            font-weight: 500;
            font-size: 16px;
          }
        }
        @media screen and (max-width: 768px) {
          table thead {
            tr {
              th:not(:last-child) {
                display: none;
              }
              th:last-child {
                width: 100vw;
                .ant-dropdown-trigger {
                  width: 32px;
                  justify-content: center;
                  border: 1px solid #b4ad6d;
                  margin-inline: 4px 0;
                  font-size: 14px;
                }
              }
            }
          }
        }
      }
    }
  }
}

body .ant-table-wrapper {
  .ant-table {
    border-radius: 8px;
    overflow: hidden;
    font-size: 15px;
    font-family: $font-family;
    min-height: 250px;
    .ant-table-title {
      padding: 0;
    }
    .ant-table-container {
      .ant-table-content {
        overflow: auto;
        table {
          .ant-table-thead {
            tr {
              th {
                @media screen and (max-width: 768px) {
                  display: none;
                }
              }
            }
            .ant-table-cell {
              background: $theme_color;
              color: $primary-black;
              font-size: 15px;
              font-weight: 900;
              text-decoration: solid;
              &::before {
                background-color: #555;
              }
            }
          }
          .ant-table-tbody {
            tr {
              @media screen and (max-width: 768px) {
                border-bottom: 3px solid #ddd;
                display: block;
                margin-bottom: 0.625em;
              }
              .table-header_orderID {
                display: flex;
                align-items: center;
                gap: 10px;
                @media screen and (max-width: 768px) {
                  justify-content: right;
                }
                .table-header_orderID-svg {
                  border: 1px solid $primary-black;
                  border-radius: 50%;
                  height: 10px;
                  width: 10px;
                }
              }
              .ant-table-cell-row-hover {
                background-color: #f4f4f4;
                color: $primary-black;
                .table-header_orderID {
                  display: flex;
                  align-items: center;
                  .table-header_orderID-svg {
                    background-color: $primary-black;
                    border-radius: 50%;
                    height: 10px;
                    width: 10px;
                  }
                }
                .preview {
                  svg {
                    color: $link_color !important;
                  }
                }
                a {
                  color: $primary-black !important;
                }
              }

              td {
                border-bottom: 1px solid #eee2e2;
                color: $dark_gray;
                font-weight: 500;
                font-size: 16px;
                // min-width: 120px;
                &:empty {
                  &::after {
                    @media screen and (max-width: 768px) {
                      content: "N/A";
                    }
                  }
                }
                &::before {
                  @media screen and (max-width: 768px) {
                    content: attr(data-label);
                    float: left;
                    font-weight: 600;
                    color: #000;
                    margin-right: 15px;
                  }
                }
                &:last-child {
                  @media screen and (max-width: 768px) {
                    bottom: 0;
                  }
                }
                @media screen and (max-width: 768px) {
                  border-bottom: 1px solid #ddd;
                  display: block;
                  font-size: 14px;
                  text-align: right;
                }
                .ant-tag {
                  font-weight: 500;
                  border-radius: 100px;
                  font-family: $font-family;
                }
                .inreview {
                  color: #566ff7 !important;
                  font-weight: 500 !important;
                  font-size: 16px !important;
                  display: flex;
                  align-items: center;
                  gap: 5px;
                  @media screen and (max-width: 768px) {
                    justify-content: right;
                    font-size: 14px !important;
                  }
                }
                .processed {
                  color: #00b9b9 !important;
                  font-weight: 500 !important;
                  font-size: 16px !important;
                  display: flex;
                  align-items: center;
                  gap: 5px;
                  @media screen and (max-width: 768px) {
                    justify-content: right;
                    font-size: 14px !important;
                  }
                }
                .other {
                  color: #483f3f90 !important;
                  font-weight: 500 !important;
                  font-size: 16px !important;
                  display: flex;
                  align-items: center;
                  gap: 5px;
                  @media screen and (max-width: 768px) {
                    justify-content: right;
                    font-size: 14px !important;
                  }
                }
                &[data-label="Current Status"] {
                  div {
                    font-size: 14px !important;
                    @media screen and (max-width: 768px) {
                      justify-content: right;
                    }
                  }
                }
                &[data-label="Previous Status"] {
                  div {
                    font-size: 14px !important;
                    @media screen and (max-width: 768px) {
                      justify-content: right;
                    }
                  }
                }
                .table-btn {
                  padding-inline: 7px;
                  font-size: 12px;
                  font-weight: 500;
                  line-height: 20px;
                  display: inline-block;
                  border-radius: 100px;
                }
                a {
                  color: $dark_gray !important;
                  font-weight: 500;
                }
              }
              &:last-child {
                @media screen and (max-width: 768px) {
                  border-bottom: 0;
                }
                td {
                  border-bottom: 0;
                  @media screen and (max-width: 768px) {
                    border-bottom: 1px solid #eee2e2;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .ant-pagination {
    justify-content: center;
    .ant-pagination-prev {
      display: flex;
      align-items: center;
      button {
        width: 0;
        height: 0;
        border-top: 11px solid transparent;
        border-bottom: 11px solid transparent;
        border-right: 9px solid $primary-black;
        background-color: transparent !important;

        span {
          display: none;
        }
      }
    }
    .ant-pagination-next {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      button {
        width: 0;
        height: 0;
        border-top: 11px solid transparent;
        border-bottom: 11px solid transparent;
        border-left: 9px solid $primary-black;
        background-color: transparent !important;
        span {
          display: none;
        }
      }
    }
    .ant-pagination-next {
      color: $primary-black;
    }
    .ant-pagination-item {
      border-color: $dark_gray;
      border-radius: 100px;
      &.ant-pagination-item-active {
        border-color: $primary-black;
        background-color: $primary-black;
        a {
          color: $primary-white;
        }
      }
      a {
        font-weight: 500;
        color: $primary-black;
      }
    }
    .ant-pagination-item-link {
      border-radius: 100px;
    }
  }
}
body {
  .ant-select-dropdown {
    .rc-virtual-list {
      .rc-virtual-list-holder-inner {
        gap: 4px;
        .ant-select-item {
          .ant-select-item-option-content {
            font-weight: 500;
          }
          &.ant-select-item-option-active {
            background-color: #f5f5f5;
            color: $primary-black;
            border-radius: 100px;
          }
          &.ant-select-item-option-selected {
            background-color: $primary-black;
            color: $primary-white;
            border-radius: 100px;
          }
        }
      }
    }
  }
}

// USER DASHBOARD
.user-main-dashboard {
  display: flex;
  min-height: 100vh;
  background-color: $theme_color;
  align-items: center;
  padding: 20px 20px 20px 0;
  flex-direction: row;
  .user-main-container {
    display: flex;
    min-height: 94vh;
    background-color: $primary-white;
    border-radius: 20px;
    width: -webkit-fill-available;
    flex-direction: column;
    padding: 20px;
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.user-main-section {
  .user-body {
    .user-box {
      width: 100%;
      margin-top: 20px;
      background-color: $primary-white;
      color: rgb(33, 43, 54);
      transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      background-image: none;
      overflow: auto;
      box-shadow: rgba(145, 158, 171, 0.24) 0px 0px 2px 0px,
        rgba(145, 158, 171, 0.24) 0px 10px 25px -4px;
      border-radius: 10px;
    }
  }
  .user-table-fl {
    display: flex;
    align-items: center;
    gap: 15px;
    @media screen and (max-width: 768px) {
      justify-content: space-between;
      width: 100%;
      padding: 10px 0 0 0;
    }
    .user-logo {
      height: 36px;
      width: 36px;
      min-width: 36px;
      background-color: $primary-black;
      color: $primary-white;
      text-align: center;
      border-radius: 50%;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      @media screen and (max-width: 768px) {
        font-size: 14px;
      }
    }

    .user-name-text {
      white-space: nowrap;
      width: 210px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .user-action-row {
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
    .tooltip-size {
      width: max-content !important;
    }
    @media screen and (max-width: 768px) {
      justify-content: flex-end;
    }
  }
  .user-head {
    .user-title {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-wrap: wrap;
      .user-right {
        margin-top: 25px;
        .btn {
          border: 1px solid $primary-black;
          border-radius: 5px;
          font-weight: 400;
          font-size: 15px;
          line-height: 20px;
          padding: 7px 15px;
          transition: all 0.5s ease-in-out;
        }
      }
    }
  }
  .user-store-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    align-items: center;
    margin-bottom: 35px;
    flex-wrap: wrap;
    .store-user-title {
      border-left: 8px solid $theme_color;
      span {
        margin-left: 10px;
        font-size: 20px;
        font-weight: bold;
      }
    }
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    border-radius: 3px !important;
    color: $primary-white !important;
  }
  :where(.css-ed5zg0).ant-checkbox-disabled .ant-checkbox-inner {
    border-radius: 3px !important;
    color: red;
  }
  :where(.css-ed5zg0).ant-checkbox-disabled .ant-checkbox-inner:after {
    border-color: $primary-white !important;
  }
}

.user-modal-body {
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
  .displayname_input {
    height: 50px !important;
  }
  input {
    height: 40px;
  }
  .ant-select {
    .ant-select-selector {
      height: 50px !important;
      .ant-select-selection-item {
        display: flex;
        align-items: center;
      }
      .ant-select-selection-search {
        height: 50px !important;
        input {
          height: 50px !important;
        }
      }
    }
  }
  .ant-select-selection-placeholder {
    height: 50px;
    display: flex;
    align-items: center;
  }
  .ant-select-selector,
  .ant-picker {
    background: $light-white !important;
    padding: 11px 16px !important;
    height: 40px !important;
    border: 0.5px solid $dark_gray !important;
    border-radius: 7px !important;
    align-items: center;
    gap: 16px;
  }
}
.user-modal-permission {
  .user-modal-permission-title {
    line-height: 1.5;
    font-family: $font-family;
    font-size: 20px;
    font-weight: 500;
    border-bottom: 1px solid;
  }
  .user-modal-permission-option {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin: 20px auto;
    span {
      font-size: 15px;
      font-weight: 500;
    }
  }
}

// User Edit Model
.edit-user-model {
  .edit-user-model-title {
    font-family: $font-family;
    font-size: 20px;
    font-weight: 600;
  }
  .edit-user-model-button {
    margin-top: 20px;
    width: 100%;
    display: flex;
    gap: 10px;
    .delete-user-button {
      width: 100%;
      height: 40px;
      background-color: #ff4d4f;
      color: $primary-white;
      border-radius: 7px;
      transition: 0.6s ease all;
      font-weight: 500;
      &:hover {
        background-color: $primary-white;
        color: $primary-black;
        border: 1px solid $primary-black;
      }
    }
    .resetpassword-link-button {
      width: 100%;
      height: 40px;
      background-color: $primary-black;
      color: $primary-white;
      border-radius: 7px;
      transition: 0.6s ease all;
      font-weight: 500;
      &:hover {
        background-color: $primary-white;
        color: $primary-black;
        border: 1px solid $primary-black;
      }
      @media only screen and (max-width: 500px) {
        height: auto;
        min-height: 40px;
        span {
          text-wrap: wrap;
        }
      }
    }
    @media only screen and (max-width: 500px) {
      flex-direction: column;
    }
  }
  .edit-user-model-field-section {
    margin-top: 20px;
    .edit-user-model-field {
      padding: 12px 10px;
    }
  }
  .edit-permission-title {
    font-size: 17px;
    font-weight: 600;
  }
  .edit-model-bottom-section {
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    .edit-user-permission {
      display: flex;
      flex-direction: column;
      gap: 12px;
      margin-top: 15px;
      // margin: 20px auto;
      span {
        font-size: 15px;
        font-weight: 500;
      }
    }
  }
  .edit-user-update-button {
    margin-top: 30px;
    display: flex;
    justify-content: flex-end;
  }
}
// user delete confirm modal
.approve-popup {
  .ant-modal-content {
    padding: 0 0 10px 0 !important;
  }
}

.user-delete-confirm-model {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  .image-container {
    width: 100%;
    display: flex;
    justify-content: center;
    background: $theme_color;
    padding-top: 10%;
    margin-bottom: 14%;
    border-radius: 8px 8px 0 0;
    svg {
      margin-bottom: -9%;
    }
  }
  .user-delete-text {
    font-size: 21px;
    font-weight: 500;
    text-align: center;
    padding: 0 10px;
  }
  .user-delete-button {
    padding: 0 20px;
    margin-right: 10px;
    margin-top: 30px;
    font-weight: 500;
    border-radius: 7px;
    background-color: $primary-white;
    border: 1px solid $primary-black;
    color: $primary-black;
    transition: 0.6s ease all;
    &:hover {
      background-color: $primary-white !important;
      color: $primary-black !important;
    }
  }
  .user-delete-yes-button {
    padding: 0 30px;
    margin-right: 10px;
    margin-top: 30px;
    background-color: $primary-black;
    border: 1px solid $primary-black;
    font-weight: 500;
    border-radius: 7px;
    color: $primary-white;
    transition: 0.6s ease all;
    &:hover {
      background-color: $primary-black !important;
      color: $primary-white !important;
    }
  }
}
// common component
.loader-icon {
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
}

// Account
.account-main-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 35px;
  .account-information {
    align-items: center;
    justify-content: center;
    display: flex;
    min-width: 500px;
    .account-merchant-box {
      width: 100%;
      margin-top: 30px;
      padding: 35px 35px 20px 35px;
      color: rgb(33, 43, 54);
      transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      background-image: none;
      box-shadow: $box-shadow;
      border-radius: 10px;
      .merchant-title {
        font-family: $font-family;
        font-size: 16px;
        font-weight: bold;
        background: $theme_color;
        border-radius: 10px;
        overflow: auto;
        text-align: center;
        padding: 10px 0;
      }
      .merchant-body {
        margin-top: 20px;
        .account-merchant-details {
          .ant-form-item-label {
            padding: 0;
          }
          label {
            font-size: 15px;
            font-weight: 500;
            color: $primary-black;
          }
          input {
            height: 40px;
            background-color: transparent;
            color: $primary-black !important;
          }
          margin-top: 20px;
          button {
            background: black;
            display: flex;
            align-items: center;
            font-size: 21px;
            gap: 5px;
            padding: 5px 20px;
            border-radius: 7px !important;
            color: white;
            border: 2px solid;
          }
        }
      }
    }
  }
  .account-reset-btn {
    margin-top: 30px;
  }
}

.terms-and-conditions-main-section {
  padding: 24px;
  .top-heading-section {
    text-align: center;
    color: #546372;
    h2 {
      font-size: 50px;
      margin: 20px;
    }
  }
  .bottom-body-section {
    color: #677788;
  }
}

.reset-password-model-body {
  display: flex;
  gap: 20px;
  padding: 45px 30px;
  flex-direction: column;
  svg {
    font-size: 20px;
  }
  input {
    outline: none;
    font-size: 16px;
    height: 40px;
    background-color: transparent !important;
    box-shadow: none;
    transition: all 0.4s ease;
  }
}
.reset-password-model-button {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  .btn {
    padding: 7px;
    font-size: 15px;
    width: 100px;
  }
}

.statement-well-back {
  font-size: 24px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  // align-items: center;
  @media only screen and (max-width: 1025px) {
    flex-direction: column;
  }
}
.filters {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  align-items: center;
  @media only screen and (max-width: 576px) {
    .ant-select {
      width: 100% !important;
    }
    .ant-picker {
      width: 100% !important;
    }
  }
}
.statement-body-desc {
  colgroup {
    width: 70%;
    @media only screen and (max-width: 1124px) {
      width: 50%;
    }
  }
}

.claim-detail-main {
  display: flex;
  min-height: 100vh;
  background-color: $theme_color;
  align-items: center;
  padding: 20px 20px 20px 0;
  flex-direction: row;
  @media only screen and (max-width: 1124px) {
    padding: 20px;
  }
  .claim-detail-container {
    display: flex;
    min-height: 94vh;
    background-color: $primary-white;
    border-radius: 20px;
    width: -webkit-fill-available;
    flex-direction: column;
    padding: 20px;
    flex: 0 0 100%;
    max-width: 100%;
    .order-detail-header {
      display: flex;
      align-items: flex-start;
      .arrow-box {
        width: max-content;
        padding: 20px;
        background-color: #fff;
        margin-bottom: 20px;
        border-radius: 10px;
        border: none;
        transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        background-image: none;
        overflow: auto;
        box-shadow: rgba(145, 158, 171, 0.18) 0px 0px 2px 0px,
          rgba(145, 158, 171, 0.18) 0px 16px 32px -4px;
      }
      .order-detail-header-content {
        align-items: center;
        margin-left: 20px;
        .header-content-title {
          font-size: 13px;
          font-family: $font-family;
          font-weight: 400;
          margin-bottom: 9px;
        }
        .header-content-heading {
          font-size: 20px;
          margin: 0;
          font-family: $font-family;
          font-weight: 600;
        }
        @media (max-width: 368px) {
          .header-content-heading {
            font-size: 16px;
          }
        }
      }
    }
    .claim-details-main-section {
      .box {
        position: relative;
        font-family: $font-family;
        padding: 20px;
        background-color: $primary-white;
        margin-bottom: 20px;
        border-radius: 10px;
        transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        background-image: none;
        overflow: auto;
        box-shadow: rgba(145, 158, 171, 0.18) 0px 0px 2px 0px,
          rgba(145, 158, 171, 0.18) 0px 16px 32px -4px;
      }
      .note-timeline {
        max-height: 300px;
        overflow-y: auto;
        margin: 0 -10px;
        padding: 0 10px;
      }
      .claim-log {
        position: relative;
        font-family: $font-family;
        padding: 20px;
        background-color: $primary-white;
        margin-bottom: 20px;
        border-radius: 10px;
        transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        background-image: none;
        overflow: auto;
        box-shadow: rgba(145, 158, 171, 0.18) 0px 0px 2px 0px,
          rgba(145, 158, 171, 0.18) 0px 16px 32px -4px;
      }
      .claim-details-df {
        box-sizing: border-box;
        display: flex;
        flex-flow: row wrap;
        width: 100%;
        justify-content: space-between;
        margin-top: 25px;
        .card-details-heading {
          flex-basis: 100%;
          flex-grow: 0;
          max-width: 100%;
          margin-bottom: 21px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 15px 0px;
          border-bottom: 1px solid #eaeaea;
          border-top: 1px solid #eaeaea;
          @media screen and (max-width: 576px) {
            flex-wrap: wrap;
            gap: 15px;
          }
          .Heading-left {
            @media screen and (max-width: 576px) {
              width: 100%;
            }
            h2 {
              margin: 0;
              font-size: 24px;
              line-height: normal;
              color: $primary-black;
              font-weight: 500;
            }
            h6 {
              margin: 0;
              font-weight: 500;
              font-size: 15px;
              color: $primary-black;
              .claim-id {
                color: $link_color;
              }
            }
          }
          .name {
            .merchantName {
              margin: 0;
              font-weight: bold;
            }
          }
          .Heading-right {
            @media screen and (max-width: 576px) {
              width: 100%;
            }
            .ant-select {
              @media screen and (max-width: 576px) {
                width: 100% !important;
              }
              &:hover {
                .ant-select-selector {
                  border-color: $primary-black;
                  box-shadow: none;
                }
              }
              &.ant-select-focused {
                .ant-select-selector {
                  border-color: $primary-black;
                  box-shadow: none;
                }
              }
            }
            .ant-select-selector {
              padding: 0 12px;
              border-radius: 100px;
              background: #f5f5f5;
              .ant-select-selection-search {
                .ant-select-selection-search-input {
                  font-weight: 500;
                  color: $primary-black;
                }
              }
              .ant-select-selection-item {
                font-size: 14px;
                font-weight: 500;
                color: $primary-black;
              }
            }
            .ant-select-arrow {
              color: $primary-black;
            }
            .status-btn {
              .approved {
                color: $primary-white;
                background: #389e0d;
                border-color: #b7eb8f;
                cursor: default;
              }
              .reviewing {
                color: #d4b106;
                background: #feffe6;
                border-color: #fffb8f;
                cursor: default;
              }
              .rejected {
                color: #ac0303;
                background: #ffeded;
                border-color: #ff6969;
                cursor: default;
              }
              .new-button {
                padding: 10px 20px;
                border-radius: 7px;
                outline: none;
                border: none;
                color: $primary-white;
                &.status-btn-approved {
                  background-color: #89ba00 !important;
                }
                &.status-btn-reviewing {
                  background-color: #00b9b9 !important;
                }
                &.status-btn-closed {
                  background-color: #ee5d50 !important;
                }
                &.status-btn-resolved {
                  background-color: #02b574 !important;
                }
              }
            }
          }
        }
        .card-details-left {
          flex-basis: calc(66.6667% - 10px);
          -webkit-box-flex: 0;
          flex-grow: 0;
          max-width: calc(66.6667% - 10px);
          @media screen and (max-width: 1200px) {
            flex-basis: 100%;
            max-width: 100%;
          }
          .latest-Head {
            margin-bottom: 15px;
            font-size: 20px;
            font-weight: 600;
            border-bottom: 1px solid #bebebe;
            padding-bottom: 8px;
          }
          .latest-time {
            color: $primary-black;
            margin: 0 0 15px 0;
            display: flex;
            justify-content: space-between;
            .latest-claim-date {
              color: $light_gray;
              font-size: 14px !important;
            }
            display: flex;
            justify-content: space-between;
            align-items: center;
            p {
              margin: 5px 0;
              font-weight: 500;
            }
            span {
              color: #aeaeae;
              font-size: 11px;
              font-style: normal;
              font-weight: 500;
              line-height: 18px;
            }
            .card-details-top {
              display: grid;
              grid-template-columns: repeat(2, auto);
              gap: 20px;
            }
          }
          .latest-que {
            padding: 15px 0 0;
            border-top: 1px dashed rgba(196, 205, 213, 0.5019607843);
            line-height: 14px;
            font-weight: 500;
            .latest-que-title {
              color: $primary-black;
              font-weight: 600;
            }
            a {
              width: 100%;
              display: inline-block;
              vertical-align: top;
              margin: 10px 0 0 0;
            }
            span {
              color: $light_gray;
              cursor: pointer;
              line-height: 1.5;
            }
          }
          .tab {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px dashed rgba(196, 205, 213, 0.5019607843);
            margin: 0 0 12px 0;
            padding: 0 0 12px 0;
            .tab-left-section {
              display: flex;
              gap: 12px;
              align-items: center;
              .bg-number {
                height: 38px;
                width: 38px;
                font-size: 22px;
                background-color: #f5f5f5;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: space-around;
                font-weight: 500;
              }
              h3 {
                margin: 0;
                color: $primary-black;
                font-family: $font-family;
                font-size: 15px;
                font-weight: 500;
                line-height: 18px;
              }
              .variant-title {
                color: #89ba00;
                font-weight: normal;
                font-size: 14px;
              }
            }
            .claim-price {
              font-size: 16px !important;
            }
            p {
              font-weight: 600;
            }
          }
          .refund-section {
            .Heading-refund {
              margin-bottom: 25px;
              font-size: 20px;
              font-weight: 600;
              border-bottom: 1px solid #9f9f9f;
              padding-bottom: 8px;
            }
            .refund-main {
              display: flex;
              align-items: center;
              justify-content: space-between;
              flex-wrap: wrap;
              gap: 20px;
              @media screen and (max-width: 641px) {
                flex-wrap: wrap;
                gap: 15px;
              }
              .refund-section-main {
                display: flex;
                align-items: center;
                gap: 30px;
                @media screen and (max-width: 641px) {
                  width: 100%;
                  gap: 15px;
                }
                @media screen and (max-width: 481px) {
                  flex-wrap: wrap;
                }
                .ant-select {
                  @media screen and (max-width: 641px) {
                    width: 50% !important;
                  }
                  @media screen and (max-width: 481px) {
                    width: 100% !important;
                  }
                }
                .ant-input-number {
                  @media screen and (max-width: 641px) {
                    width: 50% !important;
                  }
                  @media screen and (max-width: 481px) {
                    width: 100% !important;
                  }
                }
                .reorder-input {
                  input {
                    font-weight: 400;
                  }
                }
                .ant-select-selector,
                .ant-picker {
                  background: $light-white !important;
                  padding: 11px 16px !important;
                  height: 41px !important;
                  border: 0.5px solid $dark_gray !important;
                  border-radius: 7px !important;
                  align-items: center;
                  gap: 16px;
                }
              }
              // .primary_btn {
              //   margin-left: auto;
              // }
            }
          }
          .Heading-Timeline {
            margin-bottom: 25px;
            font-size: 20px;
            font-weight: 600;
            border-bottom: 1px solid #9f9f9f;
            padding-bottom: 8px;
          }
          .timeline-body {
            width: 55%;
            padding: 20px 0 0 20px;
            ul {
              display: flex;
              flex-direction: column;
              padding: 6px 16px;
              -webkit-box-flex: 1;
              flex-grow: 1;

              li {
                list-style: none;
                display: flex;
                position: relative;
                min-height: 70px;
                .data-time {
                  margin: 0px auto 0px 0px;
                  line-height: 1.5;
                  font-size: 1rem;
                  font-family: "Public Sans", sans-serif;
                  font-weight: 400;
                  padding: 6px 16px;
                  text-align: right;
                  flex: 0.1 1 0%;
                  p {
                    margin: 0;
                  }
                }
                .action-text {
                  margin: 0px;
                  line-height: 1.5;
                  font-size: 1rem;
                  font-family: "Public Sans", sans-serif;
                  font-weight: 400;
                  flex: 1 1 0%;
                  padding: 6px 16px;
                  text-align: left;
                  .df {
                    display: flex;
                    flex-direction: column;
                    -webkit-box-pack: center;
                    justify-content: center;
                    height: 100%;
                    p {
                      margin: 0;
                    }
                  }
                }
              }
              .ant-timeline-item-head{
                margin-top: 10px;
              }
            }
          }
          .comments-buttons {
            display: flex;
            justify-content: flex-end;
          }
        }
        .card-details-right {
          flex-basis: calc(33.3333% - 10px);
          -webkit-box-flex: 0;
          flex-grow: 0;
          max-width: calc(33.3333% - 10px);
          @media screen and (max-width: 1350px) {
            flex-basis: 100%;
            max-width: 100%;
          }
          .claim-info {
            margin-bottom: 15px;
            font-size: 20px;
            font-weight: 600;
            border-bottom: 1px solid #bebebe;
            padding-bottom: 8px;
          }
          .claim-info-details-main {
            display: flex;
            .claim-info-details-left {
              width: 70%;
            }
            .customer-total-claims {
              width: 30%;
              text-align: right;
              @media screen and (max-width: 1550px) {
                padding-right: 0px;
              }
              .number-total {
                font-size: 71px;
                line-height: 0.9;
                display: flex;
                justify-content: flex-end;
                @media screen and (max-width: 1650px) {
                  font-size: 46px;
                }
                @media screen and (max-width: 1550px) {
                  font-size: 42px;
                }
                @media screen and (max-width: 425px) {
                  justify-content: flex-start;
                }
                sup {
                  font-size: 20px;
                }
              }
              .number-claim {
                line-height: 0.8;
              }
            }
            @media screen and (max-width: 425px) {
              flex-direction: column;
              gap: 10px;
            }
          }
          .claim-info-details {
            font-weight: 500;
            .claim-align-outer {
              // display: flex;
              // align-items: center;
              // justify-content: space-between;
              .claim-align-inner {
                .claim-title-reason {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;

                  margin: 10px 0;
                  span {
                    color: $primary-black;
                    strong {
                      font-weight: bold;
                      color: $primary-black;
                      margin-right: 5px;
                    }
                  }
                }
                .claim-reason {
                  color: $light_gray;
                  text-align: justify;
                  strong {
                    color: $primary-black;
                    font-size: 15px;
                  }
                }
              }
              .refund-status {
                text-transform: uppercase;
                background-color: #ffd700;
                padding: 5px;
                border-radius: 5px;
                color: black;
              }
            }
            .claim-info-details-title {
              font-weight: 600;
              font-size: 18px !important;
            }
            .claim-count {
              max-height: 95px;
              overflow-y: auto;
              .merchant-outer {
                border-bottom: 1px dashed rgba(196, 205, 213, 0.5019607843);
                .merchant-name-container {
                  max-width: 100%;
                  display: flex;
                  margin: 4px;
                  gap: 65px;
                  align-items: center;
                  flex-direction: row;
                  justify-content: space-between;
                  padding: 7px;
                  .merchant-left {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    .dot {
                      height: 5px;
                      width: 5px;
                      margin-right: 5px;
                      border-radius: 50px;
                      background-color: $primary-black;
                    }
                    .merchantName {
                      color: $primary-black;
                      font-size: 16px;
                      // font-weight: 600;
                    }
                  }
                  .merchant-right {
                    color: $primary-black;
                    // font-weight: bold;
                  }
                }
              }
            }
          }
          .ant-timeline-item {
            padding-bottom: 0 !important;
            .ant-timeline-item-head {
              top: 30px;
            }
            .ant-timeline-item-tail {
              margin-top: 35px;
            }
          }
          .order-dots {
            width: 17px;
            height: 17px;
            background-color: $theme_color;
            border-radius: 50%;
          }
          .order-details,
          .claim-note-details {
            .claim-date {
              color: $light_gray;
              line-height: normal;
            }
          }
          .claim-info-details,
          .claim-note-details,
          .order-details,
          .claim-info-address {
            p {
              color: #767676;
              font-size: 12px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              margin: 0;
            }
            a {
              color: $link_color;
            }
          }
          .claim-info-mail {
            display: flex;
            align-items: center;
            margin-top: 6px;
            @media screen and (max-width: 1550px) {
              font-size: 14px;
            }
            a {
              margin-left: 8px;
              color: $primary-black;
              font-weight: 600;
              text-decoration: underline;
            }
          }
          .claim-info-phone {
            display: flex;
            align-items: center;
            margin-top: 6px;
            p {
              margin-left: 8px;
              color: $primary-black;
              font-weight: 600;
            }
          }
          .order-details {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 12px;
            padding-top: 12px;
            border-top: 1px dashed rgba(196, 205, 213, 0.5019607843);
            .original-order {
              a {
                color: $primary-black;
                background-color: $light_blue;
                border-radius: 100px;
                padding: 2px 8px;
                font-size: 12px;
                display: inline-block;
                vertical-align: top;
                font-weight: 500;
                text-align: center;
              }
            }
            strong {
              font-weight: 600;
            }
          }

          .claim-note-details {
            justify-content: space-between;
            align-items: center;
            margin-top: 12px;
            padding-top: 12px;
            border-bottom: 1px dashed rgba(196, 205, 213, 0.5019607843);

            .claim-note {
              color: $light_gray;
              .claim-date {
                display: flex;
                justify-content: space-between;
              }
              strong {
                font-weight: 600;
              }
            }
          }

          .claim-info-address {
            margin-top: 15px;
            display: flex;
            // align-items: center;
            .total-info {
              color: $light_gray;
              line-height: normal;
            }
            .text-address {
              margin-left: 7px;
            }
          }
          .claim-info-Total {
            border-bottom: 1px dotted #d9d9d9;
            .total-df {
              display: flex;
              justify-content: space-between;
              color: $primary-black;
              font-weight: 500;
            }
          }
          .track-order-info {
            .track-order {
              margin-bottom: 15px;
              font-size: 20px;
              font-weight: 600;
              border-bottom: 1px solid #9f9f9f;
              padding-bottom: 8px;
            }
            a {
              color: $link_color;
            }
            .tracking-company {
              display: flex;
              flex-direction: row;
              align-items: center;
              gap: 10px;
              margin-top: 8px;
              p {
                color: #333333;
                font-size: 14px;
              }
            }
          }
        }
      }
      p {
        font-size: 15px !important;
      }
    }
    .timeline-circle {
      height: 40px;
      width: 40px;
      border-radius: 60px;
      background-color: $dark_gray;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        width: 30px;
      }
    }
    .ant-timeline-item-label {
      left: 50px;
      top: 15px;
      width: 100%;
      color: $light_gray;
      font-size: 12px;
    }
  }
}

.admin-onboard-page {
  width: 100%;
  .store-claim-title {
    border-left: 8px solid $theme_color;
    margin-bottom: 40px;
    span {
      margin-left: 10px;
      font-size: 20px;
      font-weight: bold;
    }
  }
  .admin-merchant-name {
    padding: 20px 0;
    font-size: 21px;
    border-bottom: 1px solid #eaeaea;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
    span {
      font-weight: 400;
      color: $primary-black;
    }
    .add-merchant {
      display: flex;
      align-items: center;
      gap: 10px;
      flex-wrap: wrap;
      .add-btn {
        margin-left: 10px;
        background-color: $primary-white;
        color: $primary-black;
        border: 1px solid $primary-black;
        cursor: pointer;
        border-radius: 7px !important;
        padding: 10px;
        transition: all 0.5s;
        &:hover {
          background-color: #464646;
          color: $primary-white;
        }
    @media screen and (max-width: 399px) {
      margin-top: 10px;
    }
      }
    }
  }
  .admin-onboard-table {
    width: 100%;
    margin-top: 10px;
    background-color: rgb(255, 255, 255);
    color: rgb(33, 43, 54);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-image: none;
    overflow: auto;
    box-shadow: $box-shadow;
    border-radius: 10px;
  }
  .store-detail-overview {
    margin: 20px 0;
    display: flex;
    gap: 40px;
    @media screen and (max-width: 992px) {
      flex-direction: column;
    }
    .merchant-detail-title {
      border-left: 8px solid $theme_color;
      margin-bottom: 20px;
      span {
        margin-left: 10px;
        font-size: 20px;
        font-weight: bold;
      }
      &.notes {
        margin-top: 30px;
      }
    }
    .detail-box {
      border: 2px solid #ddd;
      box-shadow: $box-shadow;
      margin-top: 20px;
      border-radius: 15px;
      padding: 25px;
    }
    .store-detail-overview-left {
      width: 50%;
      @media screen and (max-width: 992px) {
        width: 100%;
      }
      .box-title {
        font-size: 14px;
        font-weight: 600;
        padding: 0 0 10px 0;
      }
      .box-content {
        display: flex;
        margin-top: 10px;
        justify-content: space-between;
        gap: 30px;
        .box-content-title {
          font-size: 13px;
          font-weight: 400;
        }
        .box-content-content {
          font-size: 14px;
          color: $light_gray;
          text-align: end;
          word-break: break-all;
        }
      }
    }
    .store-detail-overview-right {
      width: 50%;
      @media screen and (max-width: 992px) {
        width: 100%;
      }
      .timeline-section {
        max-height: 350px;
        overflow: scroll;
        padding: 10px 0 0 1px;
        .activity-title {
          label {
            font-size: 14px;
          }
          .activity-time {
            font-size: 14px;
            color: $light_gray;
          }
        }
        .timeline-dots {
          background-color: $light_gray;
          height: 12px;
          width: 12px;
          border-radius: 30px;
          &.active {
            background-color: $theme_color;
          }
        }
      }
      .note-section {
        display: flex;
        gap: 5px;
        flex-direction: column;
        textarea {
          &:hover {
            border: 1px solid #ebe8e8;
          }
          &:focus {
            border: 1px solid #ebe8e8;
            outline: none;
          }
        }
        .order-select {
          .ant-select-selector,
          .ant-picker {
            background: $primary-white !important;
            padding: 11px 16px !important;
            height: 41px !important;
            border: 0.5px solid #ddd !important;
            border-radius: 7px !important;
            align-items: center;
            gap: 16px;
          }
        }
        .note-button {
          text-align: end;
          button {
            border-radius: 100px !important;
            background-color: $theme_color;
            font-size: 13px;
            &:hover {
              color: $primary-black;
            }
          }
        }
      }
    }
    @media only screen and (max-width: 767px) {
      flex-direction: column;
      .store-detail-overview-left {
        width: 100%;
      }
      .store-detail-overview-right {
        width: 100%;
      }
    }
  }
}
.divider {
  background-color: $theme_color;
  height: 2px;
  margin-top: 40px;
  margin-bottom: 40px;
}

.admin-store-details-section {
  width: -webkit-fill-available;
  flex: 0 0 100%;
  max-width: 100%;
  .store-details-body {
    .onBoard-body {
      .billing-title {
        border-left: 8px solid $theme_color;
        margin-bottom: 40px;
        span {
          margin-left: 10px;
          font-size: 20px;
          font-weight: bold;
        }
      }
      .billing-section {
        width: 100%;
        padding: 20px;
        background-color: $primary-white;
        color: rgb(33, 43, 54);
        overflow: hidden;
        box-shadow: $box-shadow;
        border-radius: 10px;
        border: 1px solid #ddd;
        .billing-data-section {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          gap: 15px;
          flex-wrap: wrap;
          .is-billing {
            display: flex;
            justify-content: center;
            color: $primary-black;
            font-weight: 500;
            align-items: center;
            span {
              margin-left: 4px;
            }
          }
          .disable-btn {
            background-color: #ddd;
            color: $primary-black;
            border: 1px solid $primary-black;
            cursor: pointer;
            border-radius: 7px !important;
            padding: 10px 25px;
            font-size: 15px;
            cursor: not-allowed;
          }
        }
      }
      .task-title {
        border-left: 8px solid $theme_color;
        margin-bottom: 40px;
        span {
          margin-left: 10px;
          font-size: 20px;
          font-weight: bold;
        }
      }
    }
  }
}

// Admin Dashboard Home page

.admin-home-main {
  display: flex;
  min-height: 100vh;
  background-color: $theme_color;
  align-items: center;
  padding: 20px 20px 20px 0;
  flex-direction: row;
  @media screen and (max-width: 1200px) {
    padding: 20px;
  }
  .admin-home-container {
    display: flex;
    min-height: 94vh;
    background-color: $primary-white;
    border-radius: 20px;
    width: -webkit-fill-available;
    flex-direction: column;
    padding: 20px;
    flex: 0 0 100%;
    max-width: 100%;
    .main-content {
      width: "100%";
      flex-direction: row !important;
      display: flex;
      gap: 20px;
      margin-top: 20px;
      @media screen and (max-width: 1200px) {
        flex-direction: column !important;
      }
      .left-content {
        width: 70%;
        @media screen and (max-width: 1367px) {
          width: 60%;
        }
        @media screen and (max-width: 1200px) {
          width: 100%;
        }
        .box-section {
          grid-template-columns: repeat(4, minmax(0px, 1fr));
          box-sizing: border-box;
          display: grid;
          gap: 24px;
          width: 100%;
          .first-section {
            padding: 22px;
            display: flex;
            flex-direction: column;
            width: 100%;
            position: relative;
            min-width: 0px;
            overflow-wrap: break-word;
            background: $primary-white;
            box-shadow: $box-shadow;
            border-radius: 8px;
            min-height: 83px;
            .content-view {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: space-around;
              width: 100%;
              h3 {
                font-size: 12px;
                margin: 0;
                color: $light_gray;
              }
              p {
                color: $primary-black;
                font-size: 20px;
              }
              .icon-view {
                height: 52px;
                width: 52px;
                background-color: #f6f0f0cc;
                border-radius: 7px;
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }
          }
          @media screen and (max-width: 1350px) {
            grid-template-columns: repeat(2, minmax(0px, 1fr));
          }
          @media screen and (max-width: 481px) {
            grid-template-columns: repeat(1, minmax(0px, 1fr));
          }
        }
        .graph-container {
          width: 100%;
          flex-direction: row;
          display: flex;
          gap: 3%;
          min-height: 200px;
          margin-top: 20px;
          // .round-chart {
          //   width: 50%;
          //   background-color: $primary-white;
          //   border-radius: 10px;
          //   min-height: 200px;
          //   box-shadow: $box-shadow;
          //   padding: 10px;
          //   .round-chart-content {
          //     padding: 10px;
          //     p {
          //       font-weight: bold;
          //       font-size: 16px;
          //       color: $primary-black;
          //     }
          //   }
          // }
          .bar-chart {
            width: 100%;
            background-color: $primary-white;
            border-radius: 10px;
            min-height: 200px;
            box-shadow: $box-shadow;
            .bar-chart-content {
              padding: 10px;
              p {
                font-weight: bold;
                font-size: 16px;
                color: $primary-black;
              }
            }
          }
          @media screen and (max-width: 1300px) {
            flex-direction: column;
            gap: 40px;
            // .round-chart {
            //   width: 100%;
            // }
            .bar-chart {
              width: 100%;
            }
          }
        }
        .spline-graph {
          margin-top: 20px;
          width: 100%;
          background-color: $primary-white;
          border-radius: 10px;
          min-height: 200px;
          box-shadow: $box-shadow;
          padding: 10px;
          p {
            font-weight: bold;
            font-size: 16px;
            color: $primary-black;
          }
        }
      }
      .right-content {
        width: 30%;
        @media screen and (max-width: 1367px) {
          width: 40%;
        }
        @media screen and (max-width: 1200px) {
          width: 100%;
        }
        .right-container {
          background: $primary-white;
          box-shadow: $box-shadow;
          border-radius: 15px;
          color: $primary-white;
          .recent-activities {
            padding: 5px 15px 15px 15px;
            margin-bottom: 20px;
            .left-title {
              font-weight: bold;
              color: $primary-black;
              font-size: 16px;
              margin: 0;
              padding: 15px 7px;
            }
            .recent-activities-container {
              max-height: 360px;
              overflow: scroll;
              .activities-content-view {
                display: flex;
                width: 100%;
                margin-bottom: 10px;
                flex-direction: column;
                color: $primary-black;
                padding: 7px;
                border-radius: 10px;
                width: 100%;
                &.cursor {
                  cursor: pointer;
                }
                .activities-title-view {
                  display: flex;
                  gap: 10px;
                  .activities-title {
                    font-size: 14px;
                    font-weight: 500;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    text-overflow: ellipsis;
                  }
                  &:hover {
                    background-color: #edfcff;
                  }
                }
                .activities-time-view {
                  display: flex;
                  justify-content: space-between;
                  span {
                    font-size: 13px;
                  }
                  .activities-time {
                    font-size: 12px;
                    margin-left: 27px;
                    padding: 5px 0;
                    color: $light_gray;
                  }
                }
                &:hover {
                  background-color: #edfcff;
                  transition: 0.5s ease all;
                  border-radius: 7px;
                }
              }
              .claim-content-view {
                display: flex;
                width: 100%;
                flex-direction: row;
                color: $primary-black;
                padding: 7px;
                width: 100%;
                .claim-view {
                  display: flex;
                  flex-direction: column;
                  width: 100%;
                  .claim-data {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    gap: 20px;
                    margin-bottom: 5px;
                    .claim-data-id {
                      white-space: nowrap;
                      width: 65%;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      font-size: 13px;
                      color: $light_gray;
                      &.id {
                        color: $link_color;
                        cursor: pointer;
                        font-weight: 500;
                      }
                    }
                    .claim-label {
                      font-size: 14px;
                      width: 35%;
                    }
                  }
                }
                &:hover {
                  background-color: #edfcff;
                  transition: 0.5s ease all;
                  border-radius: 7px;
                }
              }
            }
          }
        }
      }
    }
  }
}
.admin-tab-main {
  display: flex;
  min-height: 100vh;
  background-color: $theme_color;
  align-items: center;
  padding: 20px 20px 20px 0;
  flex-direction: row;
  @media screen and (max-width: 768px) {
    padding: 20px;
  }
  .admin-tab-container {
    display: flex;
    min-height: 94vh;
    background-color: $primary-white;
    border-radius: 20px;
    width: -webkit-fill-available;
    flex-direction: column;
    padding: 20px;
    flex: 0 0 100%;
    max-width: 100%;
    .tabs {
      margin-top: 20px;
    }
  }
}
// ANT tab custom style

:where(.css-xu9wm8).ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active {
  background-color: $theme_color;
}
:where(.css-xu9wm8).ant-tabs
  .ant-tabs-tab.ant-tabs-tab-active
  .ant-tabs-tab-btn {
  color: $primary-black;
}
:where(.css-xu9wm8).ant-tabs .ant-tabs-tab:hover {
  color: $primary-black;
}
:where(.css-xu9wm8).ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab {
  border-radius: 8px 8px 0 0;
}
:where(.css-xu9wm8).ant-tabs > .ant-tabs-nav .ant-tabs-nav-list {
  gap: 20px;
}
.ant-picker-panel-container {
  @media screen and (max-width: 657px) {
    margin: 0 auto !important;
  }
  .ant-picker-panels {
    @media screen and (max-width: 825px) {
      flex-direction: column;
      height: 100%;
      max-height: 309px;
      overflow: scroll;
    }
  }
}

.setting-main {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 10px;
  .setting-box {
    background-color: #fff;
    width: 48%;
    border-radius: 7px;
    padding: 25px;
    box-shadow: $box-shadow;
    height: fit-content;
    .settings-box-title {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 30px;
    }
    .store-details {
      display: flex;
      margin-top: 10px;
      justify-content: space-between;
      border-bottom: 1px solid #dddd;
      padding: 10px 0;
      gap: 15px;
      align-items: center;
      .store-detail-title {
        font-size: 14px;
        font-weight: 500;
      }
      .store-detail-content {
        font-size: 13px;
        color: $light_gray;
        text-align: end;
        display: flex;
        word-break: break-all;
      }
      .swipe-configuration {
        display: flex;
        gap: 20px;
        align-items: center;
        flex: 0 0 71px;
        justify-content: space-between;
        @media screen and (max-width: 768px) {
          gap: 15px;
          flex: 0 0 66px;
        }
        .edit-round {
          height: 32px;
          width: 32px;
          border-radius: 60px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: $theme_color;
          cursor: pointer;
        }
      }
    }
    .merchant-comm {
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid #ddd;
      .two-value-class {
        display: flex;
        justify-content: space-between;
        padding: 10px 0;
        gap: 30px;
        align-items: center;
        .edit-round {
          height: 32px;
          width: 32px;
          border-radius: 60px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: $theme_color;
          cursor: pointer;
        }
        .merchant-comm-title {
          font-size: 13px;
          font-weight: 500;
        }
        .merchant-email {
          font-size: 13px;
          color: $light_gray;
          text-align: end;
          display: flex;
        }
        @media only screen and (max-width: 425px) {
          &:last-child{
            flex-direction: column;
            align-items: start;
            gap: 10px;
          }
        } 
 
      }
    }
    @media only screen and (max-width: 1050px) {
      width: 100%;
    }
  }
}
.edit-submit {
  padding: 10px;
  background-color: $primary-white;
  color: $primary-black;
  border: 1px solid $primary-black;
  cursor: pointer;
  border-radius: 7px !important;
  transition: all 0.5s;
  &:hover {
    background-color: #464646;
    color: $primary-white;
  }
}
.drawer-footer {
  display: flex;
  gap: 10px;
  justify-content: center;
  .btn {
    width: 100%;
    max-width: 200px;
  }
  &.config-save {
    padding: 20px;
  }
}
.drawer-header {
  text-align: center;
  padding: 20px 0;
  .drawer-close-icon {
    font-size: 20px;
    cursor: pointer;
  }
}
.swipe-protection-drawer {
  margin: 0 10px 0 10px;
  .option {
    margin-left: 17px;
    margin-top: 10px;
    .checkbox {
      padding: 10px 0;
    }
  }
}
.widget-drawer {
  margin: 0 10px 0 10px;
  .option {
    margin-left: 17px;
    margin-top: 10px;
    .checkbox {
      padding: 10px 0;
      font-weight: bold;
    }
    .checkbox-content {
      margin-left: 25px;
      color: $light_gray;
      font-size: 13px;
    }
  }
}
.billingContact-drawer {
  margin: 0 10px 0 10px;
  .option {
    margin-left: 18px;
  }
}
.claimContact-drawer {
  margin: 0 10px 0 10px;
  .option {
    margin-left: 18px;
  }
}
.trackLink-drawer {
  margin: 0 10px 0 10px;
  .option {
    display: flex;
    justify-content: space-between;
    margin: 0 12px;
  }
}

:where(.css-xu9wm8).ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  border-color: $primary-black;
  background-color: $primary-black;
}
:where(.css-xu9wm8).ant-switch.ant-switch-checked:hover:not(
    .ant-switch-disabled
  ) {
  background: $primary-black;
}
:where(.css-xu9wm8).ant-switch.ant-switch-checked {
  background: $primary-black;
}
.apexcharts-tooltip span {
  color: #000;
}

//engage tab
.engage-main {
  display: flex;
  min-height: 100vh;
  background-color: $theme_color;
  align-items: center;
  padding: 20px 20px 20px 0;
  flex-direction: row;
  @media screen and (max-width: 768px) {
    padding: 20px;
  }
  .engage-container {
    display: flex;
    min-height: 94vh;
    background-color: $primary-white;
    border-radius: 20px;
    width: -webkit-fill-available;
    flex-direction: column;
    padding: 20px;
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.engage-main-section {
  .engage-content {
    display: flex;
    gap: 20px;
    justify-content: space-between;
    min-height: 94vh;
    background-color: $primary-white;
    border-radius: 20px;
    @media screen and (max-width: 992px) {
      flex-wrap: wrap;
    }
    .left-section,
    .right-section {
      width: 50%;
      @media screen and (max-width: 992px) {
        width: 100%;
        flex-wrap: wrap;
      }
      .engage-body {
        .engage-box {
          width: 100%;
          margin: 30px auto;
          padding: 24px;
          background-color: $primary-white;
          color: rgb(33, 43, 54);
          background-image: none;
          overflow: hidden;
          box-shadow: $box-shadow;
          border-radius: 10px;
          margin-top: 0;
          @media screen and (max-width: 768px) {
            padding: 20px;
          }
          .engage-box-title {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 20px;
            font-size: 16px;
            font-weight: bold;
            margin-bottom: 30px;
          }
          .engage-body-detail {
            display: flex;
            margin-top: 10px;
            justify-content: space-between;
            border-bottom: 1px solid #dddd;
            padding: 10px 0;
            gap: 30px;

            .engage-detail-title {
              font-size: 14px;
              font-weight: 500;
            }
            .engage-detail-content {
              .preview-cover {
                width: 135px;
                background: rgb(51, 51, 51);
                border-radius: 8px;
                margin: 7px 0px;
              }
              .preview-logo {
                width: 50px;
                height: 50px;
                background: rgb(51, 51, 51);
                border-radius: 8px;
                margin: 7px 0px;
              }
              font-size: 13px;
              color: $light_gray;
              text-align: end;
              display: flex;
            }
          }
          .engage-contact-box-title {
            border-bottom: 1px solid #dddd;
            padding-bottom: 30px;
            font-size: 16px;
            font-weight: bold;
            margin-bottom: 20px;
          }
          .contact-comm {
            display: flex;
            flex-direction: column;
            border-bottom: 1px solid #dddd;
            margin-bottom: 5px;
            .contact-detail-row {
              display: flex;
              justify-content: space-between;
              padding: 5px 0;
              gap: 30px;
              align-items: center;
              .title {
                font-weight: 600;
              }
              .contact-detail-row-title {
                font-size: 13px;
                font-weight: 500;
              }
              .contact-detail-row-value {
                font-size: 13px;
                color: $light_gray;
                text-align: end;
                display: flex;
                word-break: break-all;
              }
            }
          }
        }
      }
    }
    .right-section {
      .engage-table-title {
        margin-bottom: 20px;
        line-height: 42px;
      }
      .engage-body-title {
        display: table-row;
        border-top: 1px solid #dddd;
        color: $dark_gray;
        font-weight: 500;
        padding: 12px 0;
        margin-bottom: 0 !important;
      }
    }
  }

  .tabs {
    margin-top: 20px;

    .ant-tabs-content {
      .shipping-notification-tab {
        .ant-collapse {
          display: flex;
          flex-flow: column;
          gap: 30px;
          .ant-collapse-item {
            box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
            border-radius: 10px;
            padding: 24px;
            @media screen and (max-width: 992px) {
              padding: 20px;
            }
          }
          .ant-collapse-header {
            flex-flow: row-reverse;
            .ant-collapse-arrow {
              font-size: 16px;
            }
          }
          .ant-collapse-content {
            .panel-content {
              display: flex;
              justify-content: space-between;
              @media screen and (max-width: 992px) {
                flex-wrap: wrap;
              }
              .left-content {
                width: 30%;
                @media screen and (max-width: 1367px) {
                  width: 40%;
                }
                @media screen and (max-width: 992px) {
                  width: 100%;
                  margin-bottom: 30px;
                }
                .title {
                  font-size: 16px;
                  font-weight: bold;
                }
              }
              .right-content {
                width: 50%;
                @media screen and (max-width: 992px) {
                  width: 100%;
                }
                .notification-box {
                  .notification-box-content {
                    margin-bottom: 20px;
                    .notification-box-header {
                      font-size: 16px;
                      font-weight: bold;
                      margin-bottom: 30px;
                    }
                    .notification-box-body {
                      .notification-box-body-title {
                        margin-top: 10px;
                        border-bottom: 1px solid #dddd;
                        padding: 10px 0;
                        color: $light_gray;
                      }
                      .notification-box-body-row {
                        display: flex;
                        margin-top: 10px;
                        justify-content: space-between;
                        border-bottom: 1px solid #dddd;
                        padding: 10px 0;
                        gap: 30px;
                        .row-title {
                        }
                        .row-content {
                          display: flex;
                          align-items: center;
                          svg {
                            color: $light_gray;
                          }
                        }
                      }
                    }
                  }
                  .notification-box-submit {
                    margin-top: 15px;
                    display: flex;
                    justify-content: flex-end;

                    button {
                      display: flex;
                      align-items: center;
                      gap: 10px;
                    }
                    .disable-btn {
                      background-color: #ddd;
                      color: $primary-black;
                      border: 1px solid $primary-black;
                      cursor: pointer;
                      border-radius: 7px !important;
                      padding: 10px 25px;
                      font-size: 15px;
                      cursor: not-allowed;
                    }
                  }
                  @media screen and (max-width: 992px) {
                    &:not(:last-child) {
                      margin-bottom: 30px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.engage-drawer {
  .update-company-details {
    display: flex;
    flex-direction: row;
    padding: 20px 0;
    .update-company-details-mobile-preview {
      width: 600px;
      .mobile-device-container {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        .mobile-device-container-header {
          text-align: center;
          margin: 10px 0 10px;
          font-weight: 600;
          font-size: 12px;
          line-height: 13px;
          font-style: normal;
        }
        .mobile-container {
          display: flex;
          justify-content: center;
          position: relative;
          width: 100%;
          height: 100%;
          align-items: flex-start;
          .mobile-background {
            display: flex;
            justify-content: center;
            position: relative;
            .mobile-outline {
              position: absolute;
              z-index: 20;
              min-width: 425px;
            }
            .mobile-content {
              margin-top: 20px;
            }
            .mobile-content-nav-bar {
              position: absolute;
              z-index: 20;
              top: 65px;
            }
            .phone-cover-image {
              object-fit: cover;
              position: absolute;
              z-index: 10;
              top: 23px;
              border-radius: 40px 40px 0 0;
              height: 210px;
              width: 100%;
            }
            .phone-logo-image-default {
              object-fit: cover;
              position: absolute;
              z-index: 20;
              height: 54px;
              width: 54px;
              border-radius: 50%;
              top: 203px;
              left: 161px;
            }
            .phone-company-domain,
            .phone-company-name {
              position: absolute;
              text-align: left;
              white-space: nowrap;
              overflow: hidden;
            }
            .phone-company-name {
              max-width: 172px;
              height: 34px;
              top: 269px;
              font-family: Titillium Web;
              font-style: normal;
              font-weight: 700;
              font-size: 26.3158px;
              line-height: 22px;
              color: $dark_gray;
            }
            .phone-company-domain {
              top: 402px;
              left: 164px;
              width: 108px;
              height: 20px;
              margin-top: 40px;
              background: $primary-white;
              display: flex;
              align-items: center;
              justify-content: center;
              color: #31c8e0;
              font-size: 12px;
              font-weight: 600;
            }
          }
        }
      }
    }
    @media only screen and (max-width: 768px) {
      flex-direction: column-reverse;
      align-items: center;
      gap: 50px;
    }
  }
  .cover-image {
    margin-top: 50px;
  }
  .ant-upload-list {
    display: flex;
    flex-flow: row-reverse;
    justify-content: flex-end;
  }
  .ant-checkbox-group {
    flex-direction: column;
  }
}
.merchant-category-drawer {
  .info {
    font-size: 13px;
  }
  .category-type-box {
    margin-top: 30px;
    .title {
      font-weight: 600;
    }
    .description {
      font-size: 13px;
      color: $dark_gray;
    }
    .checkbox-container {
      .merchant-checkbox-group {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: 10px;
        .ant-checkbox-wrapper {
          flex-basis: 48%;
          font-size: 13px;
          margin-bottom: 8px !important;
          color: $dark_gray;
        }
      }
    }
  }
}
.company-contact-drawer {
  .info {
    font-size: 13px;
  }
}
.edit-round {
  height: 32px;
  width: 32px;
  border-radius: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $theme_color;
  cursor: pointer;
}

// order page

.onBoard-main-section {
  .order-body {
    .order-box {
      width: 100%;
      margin-top: 30px;
      background-color: $primary-white;
      color: rgb(33, 43, 54);
      transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      background-image: none;
      overflow: auto;
      box-shadow: rgba(145, 158, 171, 0.24) 0px 0px 2px 0px,
        rgba(145, 158, 171, 0.24) 0px 16px 32px -4px;
      border-radius: 10px;
      .ant-table-tbody {
        tr {
          cursor: pointer;
        }
      }
    }
  }
  .ant-checkbox-wrapper {
    margin-left: 5px !important;
    font-size: 15px;
    font-size: 16px;
    font-weight: 600;
  }
  .order-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 14px;
    // flex-wrap: wrap;
    @media screen and (max-width: 768px) {
      flex-wrap: wrap;
    }
    .order-filter {
      display: flex;
      gap: 14px;
      @media screen and (max-width: 1280px) {
        flex-wrap: wrap;
      }
      .ant-select {
        @media screen and (max-width: 768px) {
          width: 100% !important;
        }
      }
      .ant-picker {
        @media screen and (max-width: 768px) {
          width: 100% !important;
        }
      }
    }
    .export_btn {
      display: flex !important;
      justify-content: center;
      align-items: center;
      gap: 10px;
      background-color: $primary-white;
      color: $primary-black;
      border: 1px solid $primary-black;
      cursor: pointer;
      padding: 10px 20px;
      border-radius: 7px !important;
      transition: all ease-in-out 0.5s;
      .export-icon{
      transition: all ease-in-out 0.5s;
      }
      &:hover {
        background-color: #464646;
        color: $primary-white;

        .export-icon {
          filter: brightness(0) invert(1);
        }
      }
    }
    //search input box
    .ant-input-group-wrapper {
      background-color: $theme_color;
      padding: 0px 0px 0px 0px;
      border-radius: 25px;
      border: 1px solid $dark_gray;
      max-width: 300px;
      .ant-input-wrapper,
      .ant-input {
        border: none;
        padding: 0px;
        padding: 5px 10px;
        background-color: transparent;
        .ant-btn {
          background-color: transparent;
          border: none;
        }
        .ant-input-group-addon .ant-btn {
          cursor: auto;
          background-color: black;
          border-radius: 20px;
          margin-right: -1px;
        }
      }
    }
  }
}
.protect-container {
  display: flex;
  align-items: center;
  gap: 9px;
  .protected-true {
    color: #26e126;
  }
}

.account-setting-main {
  margin-top: 15px;
  .setting-page-title {
    font-weight: bold;
    font-size: 18px;
  }
  .setting-box {
    margin-top: 15px;
    min-height: 250px;
    padding: 20px 0;
    border-radius: 10px;
    .select-method-text {
      color: $dark_gray;
    }
    .payment-method {
      margin-top: 30px;
      display: flex;
      flex-direction: column;
      gap: 15px;
      .ant-radio-inner {
        height: 22px;
        width: 22px;
      }
    }
    .stripe-text {
      margin-top: 30px;
      .stipe-title {
        font-size: 12px;
        color: $dark_gray;
        font-weight: 600;
        span {
          color: #6772e5;
          font-size: 18px;
          margin-left: 5px;
          font-weight: bold;
        }
      }
      .stripe-desc {
        font-size: 12px;
        width: 430px;
        color: $light_gray;
        @media only screen and (max-width: 768px) {
          width: 100%;
        }
      }
    }
    .bank-form {
      margin-top: 30px;
      display: flex;
      flex-direction: column;
      gap: 5px;
      width: 450px;
      box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
      padding: 20px;
      border-radius: 7px;
      .ant-select-selector,
      .ant-picker {
        background: $light-white !important;
        padding: 11px 16px !important;
        height: 41px !important;
        border: 0.5px solid #ddd !important;
        border-radius: 7px !important;
        align-items: center;
        gap: 16px;
      }
      @media only screen and (max-width: 768px) {
        width: 100%;
      }
      .bank-info-title {
        text-align: center;
        font-size: 16px;
        font-weight: 600;
        padding: 5px 0 15px 0;
      }
      &.gap {
        gap: 20px;
        input {
          border: none !important;
          border: 1px solid #aab7c460 !important;
          background-color: #aab7c415;
        }
      }
    }
    .card-form {
      border: 1px solid #aab7c460;
      margin-top: 30px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      width: 450px;
      box-shadow: $box-shadow;
      padding: 20px;
      border-radius: 7px;
      input {
        border: none !important;
        border: 1px solid #aab7c460 !important;
        background-color: #aab7c415;
      }
      @media only screen and (max-width: 768px) {
        width: 100%;
      }
      .name-on-card {
        outline: none;
        border: none;
        background-color: $primary-white;
        &::placeholder {
          color: #aab7c4;
        }
      }
      .exp-cvc {
        display: flex;
        gap: 20px;
      }
      .card-info-title {
        text-align: center;
        font-size: 16px;
        font-weight: 600;
      }
      .card-label {
        margin-bottom: -18px;
        color: grey;
        font-size: 14px;
      }
    }
    .account-setting-btn {
      display: flex;
      gap: 10px;
      justify-content: center;
      margin-top: 20px;
    }
  }
}

@media only screen and (max-width: 1025px) {
  html:has(header input:checked) {
    overflow: hidden;
  }
  .onBoard-back-button {
    width: 100% !important;
    text-align: center;
  }
  .onBoard-welcome {
    display: none;
  }
  .timeline-body {
    width: 85% !important;
  }
}

@media only screen and (max-width: 768px) {
  .account-information {
    gap: 0px !important;
    flex-direction: column;
  }
}
@media only screen and (max-width: 576px) {
  .ant-collapse-header {
    font-size: 18px !important;
  }
  .login-button {
    margin: -25px 10px 0 !important;
    &.reset-password-btn {
      margin: 6px 0 !important;
    }
  }
  .login-form-input {
    padding: 0px 10px 45px !important;
  }
  .login-termCond {
    padding: 20px 5px 5px 5px !important;
    text-align: center;
  }
}
@media screen and (max-width: 481px) {
  .register-page-container {
    height: 100%;
    flex-flow: column-reverse;
    .register-left-side {
      width: 100%;
      padding-bottom: 20px;
      .data-container {
        width: 330px;
      }
    }
    .register-right-side {
      padding: 20px 0;
      .register-form-view {
        .form-input {
          width: 330px;
        }
        .name-email {
          .form-input-name-email {
            width: 160px;
          }
        }
      }
    }
  }
  .session-Model {
    .session-modal-text {
      text-align: center;
      h3 {
        margin-bottom: 0;
        font-size: 25px;
      }
      p {
        font-size: 15px;
      }
    }
    .session-modal-btn {
      .btn {
        font-size: 15px;
        padding: 9px 25px;
      }
    }
  }
}
.setup-guide-main {
  display: flex;
  min-height: 100vh;
  background-color: $theme_color;
  align-items: center;
  padding: 20px 20px 20px 0;
  flex-direction: row;
  @media screen and (max-width: 768px) {
    padding: 20px;
  }
  .setup-guide-container {
    display: flex;
    min-height: 94vh;
    background-color: $primary-white;
    border-radius: 20px;
    width: -webkit-fill-available;
    flex-direction: column;
    padding: 20px;
    flex: 0 0 100%;
    max-width: 100%;
    .setup-guide-section {
      margin-top: 10px;
      .setup-tabs-layout {
        .ant-tabs-nav {
          .ant-tabs-nav-wrap {
            .ant-tabs-nav-list {
              gap: 0px !important;
            }
          }
          &::before {
            bottom: -10px !important;
          }
        }
        .ant-tabs-content-holder {
          border-radius: 15px !important;
          box-shadow: lightgray 0px 0px 18px 5px !important;
          padding: 24px !important;
          // padding-top: 30px !important;
          margin-top: 15px !important;
          @media screen and (max-width: 768px) {
            padding: 20px !important;
          }
        }
      }

      .ant-tabs-tab {
        padding: 8px 25px;
        background: #efefef;
        border-radius: 30px;
        cursor: pointer;
        margin-top: 10px;
        margin-bottom: 10px;
        &:hover {
          color: black !important;
        }
      }
      .ant-tabs-tab-active {
        background-color: black;
        .ant-tabs-tab-btn {
          color: white !important;
        }
      }
      .ant-tabs-ink-bar-animated {
        display: none;
      }
      .ant-tabs .ant-tabs-tab-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
      }
      .setup-guide-tabs {
        margin-top: 8px;
        padding-top: 8px;
        padding-bottom: 8px;
        border-top: 1px solid #efefef;
        border-bottom: 1px solid;
        .setup-guide-tab-df {
          display: flex;
          align-items: center;
          gap: 30px;
          .tab {
            padding: 8px 25px;
            background: #efefef;
            border-radius: 30px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
// .dismiss-btn {
//   border: none;
//   cursor: pointer;
//   display: flex;
//   gap: 8px;
//   margin-bottom: 8px;
// }
.btn-content {
  display: flex;
  flex-direction: column;
  .btn-content-block {
    display: flex;
    flex-direction: row;
    gap: 8px;
    flex-wrap: wrap;
    .setup-guide-btn {
      // height: 26px !important;
      margin-bottom: 6px !important;
      font-size: 12px !important;
      background-color: $primary-black;
      border: none;
      color: #fff;
      padding: 4px 15px !important;
      border-radius: 4px !important;
      box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
      outline: none;
      text-align: center;
      cursor: pointer;
      transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
      user-select: none;
      touch-action: manipulation;
      line-height: 1.5714285714285714;

      span {
        padding-left: 8px;
      }

      &:hover {
        background-color: #e5e5e5 !important;
        border-radius: 0px !important;
        color: $primary-black !important;
      }
    }
  }
}

.resource-card-box {
  display: flex;
  -webkit-box-pack: justify;
  transition: box-shadow 0.2s, border-color 0.2s;
  .card-a {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.88);
    font-size: 14px;
    line-height: 1.5714285714285714;
    list-style: none;
    position: relative;
    background: #ffffff;
    border: 1px solid #e5e5e5;
    width: 100%;
    transition: box-shadow 0.2s, border-color 0.2s;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .r-card-head {
      display: flex;
      justify-content: center;
      flex-direction: column;
      min-height: 56px;
      padding: 0 24px;
      padding-top: 13px;
      color: rgba(0, 0, 0, 0.88);
      font-weight: 600;
      font-size: 16px;
      background: $primary-white;

      .card-head-wrapper {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .card-head-title {
          display: inline-block;
          flex: 1;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;

          svg {
            height: 32px;
            width: 32px;
            fill: rgb(26, 30, 34);
          }
        }
      }
    }

    .r-card-body {
      padding: 16px 24px;
      display: flex !important;
      height: -webkit-fill-available;

      .r-body-content {
        display: flex;
        gap: 10px;
        flex-direction: column;
        justify-content: space-between;

        .texts-body {
          .r-texts {
            color: #767676;
            font-size: 13px;
            letter-spacing: 0;
            line-height: 19px;
          }
        }

        .card-head-extra {
          color: rgba(0, 0, 0, 0.88);
          font-weight: normal;
          font-size: 14px;
        }
      }
    }
  }
  &:hover {
    border-color: transparent;
    box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16),
      0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
  }
}

.welcome-tab-main-section {
  .tab-container {
    .titles {
      p:first-child {
        margin: 0px 0px 32px;
        font-size: 56px;
        letter-spacing: -0.02em;
        line-height: 58px;
      }
    }
    .cong {
      width: 100%; /*50%*/
      padding-bottom: 20px;
      .protect {
        font-size: 30px;
        color: $primary-black;
        padding: 10px 0;
        margin-bottom: 16px;
        // margin-left: -22px;
        width: 100%;
        display: inline-block;
        vertical-align: top;
      }
      .party-icons {
        display: flex;
        gap: 0.5rem;
      }
      .great-texts {
        color: $dark_gray;
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0.54px;
        margin-top: 8px;
      }
      .lets-texts {
        color: $primary-black;
        overflow-wrap: break-word;
        font-size: 24px;
        line-height: 1.5;
        font-weight: bold;
        max-width: 40.5rem;
      }
    }
    .resolve {
      width: 45%;
      @media screen and (max-width: 992px) {
        width: 100%;
      }
      .protect {
        .great-texts {
          background: linear-gradient(152deg, #000000, #a7a7a7);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          font-size: 16px;
          background-clip: text;
        }
      }
    }
    .protect-head {
      font-size: 24px;
      line-height: 1.5;
      font-weight: bolder;
    }
    .track-head {
      margin-top: 0; /*40px*/
    }
    .bordered-line {
      height: 2px;
      background-color: #e1e1e1;
    }
    .track-pg-w {
      width: 60% !important;
    }
    .finance-pg-w {
      width: 100% !important;
    }
    .welcome-tab-desc {
      width: 50%;
      margin-top: 20px;
      @media screen and (max-width: 992px) {
        width: 100% !important;
      }
      p {
        font-size: 16px;
        margin-bottom: 30px;
        line-height: 1.5;
        span {
          color: $dark_gray;
        }
      }
      .finance-img-layout {
        display: flex;
        flex-direction: column;

        img {
          vertical-align: middle;
          height: auto;
        }
        .finance-contents {
          margin-top: 12px;
          margin-bottom: 40px;
          display: flex;
          flex-direction: row;
          gap: 80px;
          @media screen and (max-width: 768px) {
            flex-wrap: wrap;
            gap: 30px;
            margin-top: 30px;
          }
          p {
            width: 160px;
            font-size: 12px;
            letter-spacing: 0.5px;
            line-height: 17px;
            @media screen and (max-width: 768px) {
              width: 100%;
              margin: 0;
            }
          }
        }
        .finance-plan-note {
          display: flex;
          padding: 12px 16px;
          flex-direction: column;
          align-items: flex-start;
          border-radius: 6px;
          background: rgb(239, 239, 239);
          margin-bottom: 48px;

          .note-layout {
            display: flex;

            p {
              color: rgba(0, 0, 0, 0.6);
              margin: 0px;
              display: inline-block;
              font-size: 14px;
              letter-spacing: 0;
              line-height: 19px;
            }
          }
        }
      }
      .start-btn {
        margin-bottom: 30px;
        .start-btn-cont {
          border: none;
          background: #262624;
          padding: 10px 18px;
          border-radius: 10px;
          color: white;
          font-size: 16px;
          cursor: pointer;
          margin-bottom: 30px;
          margin-top: 15px;
        }
      }
    }
    .protect-plan-cards-box {
      margin-bottom: 70px;
      .finance-card-texts {
        max-width: 800px;
        margin-top: 20px;
        margin-bottom: 30px;
        font-size: 16px;
        line-height: 1.5;
      }
      .protect-plan-cards {
        display: flex;
        padding: 24px 24px 24px 32px;
        background: rgb(239, 239, 239);
        border-radius: 6px;
        margin-bottom: 16px;
        @media screen and (max-width: 768px) {
          padding: 20px;
        }
        .plan-card-dv1 {
          display: flex;
          flex-direction: column;
          -webkit-box-flex: 1;
          flex-grow: 1;
          width: 100%;

          .plan-card-dv2 {
            display: flex;
            flex-direction: row;
            flex-grow: 1;
            width: 100%;
            justify-content: space-between;
            @media screen and (max-width: 992px) {
              flex-direction: column;
            }
            .plan-content1 {
              display: flex;
              margin-bottom: 8px;
              svg {
                min-width: 12px;
              }
              @media screen and (max-width: 768px) {
                svg {
                  margin: auto 12px auto 0px !important;
                  min-width: 12px;
                  @media screen and (max-width: 576px) {
                    min-width: 32px;
                    min-height: 32px;
                    margin-bottom: 12px !important;
                  }
                }
              }
              @media screen and (max-width: 576px) {
                flex-wrap: wrap;
              }

              .text-main-1 {
                display: flex;
                margin-bottom: 8px;
                align-items: center;
                @media screen and (max-width: 576px) {
                  flex-wrap: wrap;
                  gap: 10px;
                }
                p {
                  font-size: 14px;
                  line-height: 16px;
                  color: rgb(0, 0, 0);
                  margin: 0px 6px 0px 0px;
                }
              }
              .text-main-2 {
                color: rgba(0, 0, 0, 0.6);
                font-size: 14px;
                line-height: 19px;
              }
            }

            .plan-content2 {
              display: flex;
              min-width: 30%;
              align-items: center;
              -webkit-box-pack: end;
              justify-content: flex-end;
              @media screen and (max-width: 992px) {
                justify-content: flex-start;
                margin-left: 44px;
              }
              @media screen and (max-width: 768px) {
                margin-left: 22px;
              }
              @media screen and (max-width: 576px) {
                margin-left: 0;
              }
              .plan-content2-text {
                color: rgb(0, 0, 0);
                display: flex;
                -webkit-box-align: center;
                align-items: center;
                // text-align: right;
                font-size: 12px;
                line-height: 17px;
                gap: 15px;
                @media screen and (max-width: 641px) {
                  flex-flow: column;
                  align-items: baseline;
                }
              }
              .skip-btn {
                border: none;
                background: transparent;
                display: flex;
                gap: 5px;
                cursor: pointer;
              }
            }
            .skip-text {
              text-align: right;
              span {
                text-decoration: underline;
                cursor: pointer;
                font-weight: 600;
              }
            }
          }
        }
        .plan-card-dv3 {
          display: flex;
          gap: 10px;
          .plan-card-dv3-content {
            max-width: 750px;
          }
        }
      }
      .protect-plan-cards-yellow {
        padding: 24px 24px 24px 32px;
        background: rgb(239, 239, 239);
        border-radius: 6px;
        margin-bottom: 16px;
        @media screen and (max-width: 768px) {
          padding: 20px;
        }
        .plan-card-dv1 {
          display: flex;
          flex-direction: column;
          -webkit-box-flex: 1;
          flex-grow: 1;
          width: 100%;

          .plan-card-dv2 {
            display: flex;
            flex-direction: row;
            flex-grow: 1;
            width: 100%;
            justify-content: space-between;
            @media screen and (max-width: 992px) {
              flex-direction: column;
            }
            .plan-content1 {
              display: flex;
              margin-bottom: 8px;
              svg {
                min-width: 12px;
              }
              @media screen and (max-width: 768px) {
                svg {
                  margin: auto 12px auto 0px !important;
                  min-width: 12px;
                  @media screen and (max-width: 576px) {
                    min-width: 32px;
                    min-height: 32px;
                    margin-bottom: 12px !important;
                  }
                }
              }
              @media screen and (max-width: 576px) {
                flex-wrap: wrap;
              }

              .text-main-1 {
                display: flex;
                margin-bottom: 8px;
                align-items: center;
                @media screen and (max-width: 576px) {
                  flex-wrap: wrap;
                  gap: 10px;
                }
                p {
                  font-size: 14px;
                  line-height: 16px;
                  color: rgb(0, 0, 0);
                  margin: 0px 6px 0px 0px;
                }
              }
              .text-main-2 {
                color: rgba(0, 0, 0, 0.6);
                font-size: 14px;
                line-height: 19px;
              }
            }

            .plan-content2 {
              display: flex;
              min-width: 30%;
              align-items: center;
              -webkit-box-pack: end;
              justify-content: flex-end;
              @media screen and (max-width: 992px) {
                justify-content: flex-start;
                margin-left: 44px;
              }
              @media screen and (max-width: 768px) {
                margin-left: 22px;
              }
              @media screen and (max-width: 576px) {
                margin-left: 0;
              }
              .plan-content2-text {
                color: rgb(0, 0, 0);
                display: flex;
                -webkit-box-align: center;
                align-items: center;
                // text-align: right;
                font-size: 12px;
                line-height: 17px;
                gap: 15px;
              }
            }
          }
        }
      }
    }
    .resources-sec {
      margin-top: 10px;
      margin-bottom: 60px;
      padding-bottom: 20px;
      p {
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 20px;
      }
      .box-df {
        display: grid;
        grid-template-columns: 2fr 2fr 2fr;
        gap: 35px;
        @media screen and (max-width: 1200px) {
          grid-template-columns: 2fr 2fr;
        }
        @media screen and (max-width: 641px) {
          grid-template-columns: 2fr;
        }
      }
    }
    .faq-box {
      margin-top: 10px;
      margin-bottom: 60px;
      .texts-accordion {
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 20px;
      }
      .collapse-tab-out {
        .collapse-tab-in {
          border-bottom: none !important;
          .ant-collapse-header {
            align-items: center !important;
            background-image: linear-gradient(
              50deg,
              hsl(0deg 0% 100%) 0%,
              hsl(53deg 100% 88.62%) 102%,
              #fdf399 100%
            ) !important;
            border-bottom: 1px solid #d9d9d9 !important;
            .ant-collapse-header-text {
              font-size: 14px !important;
              &:hover {
                color: rgba(0, 0, 0, 0.6) !important;
              }
            }
          }
          .ant-collapse-content {
            padding-left: 25px !important;
            color: rgba(0, 0, 0, 0.6) !important;
            width: 55% !important;
            font-size: 14px !important;
            border-top: none !important;
            @media screen and (max-width: 1367px) {
              width: 75% !important;
            }
            @media screen and (max-width: 1200px) {
              width: 100% !important;
            }
          }
        }
      }
    }
    .widgetStep {
      width: 80%;
      @media screen and (max-width: 1020px) {
        width: 100%;
      }
    }
    .send-billing-box {
      box-shadow: $box-shadow;
      padding: 20px;
      margin-top: 20px;
      border-radius: 7px;
      .send-billing-title {
        font-weight: bold;
        font-size: 18px;
      }
      .billing-content {
        padding: 15px 0;
        text-align: justify;
        width: 80%;
      }
      button {
        background-color: $primary-black;
        color: $primary-white;
      }
      .disable-button {
        button {
          background-color: grey !important;
          border: none;
          cursor: not-allowed;
        }
      }
    }
  }
  .faq-col {
    .collapse-box {
      .collapse-box-df-title {
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }
  }
}
.widget-video {
  width: -webkit-fill-available;
  margin-top: 40px;
}

.minusArrow {
  rotate: 180deg;
}

.configBar_main {
  display: flex;
  width: 100%;
  justify-content: space-between;
  .ant-select-selector,
  .ant-picker {
    background: $light-white !important;
    padding: 11px 16px !important;
    height: 41px !important;
    border: 0.5px solid $dark_gray !important;
    border-radius: 7px !important;
    align-items: center;
    gap: 16px;
  }
  .banner-title {
    font-weight: bold;
    font-size: 16px;
    color: #767676;
  }
}

.onBoard-main-section {
  .config-main {
    .backToSetting-icon {
      cursor: pointer;
      color: $link_color;
      &:hover {
        text-decoration: underline;
      }
    }
    .config-contain {
      font-size: 12px;
      color: $dark_gray;
    }
  }
  .widget-container {
    display: flex;
    gap: 20px;
    @media screen and (max-width: 1100px) {
      flex-wrap: wrap;
    }
    .tab-container {
      width: 50%;
      @media screen and (max-width: 1100px) {
        width: 100%;
      }
    }
    .preview-contain {
      width: 48%;
      display: flex;
      justify-content: center;
      margin: 0 auto;
      .widget-cart-preview {
        width: 350px;
        margin-top: 20px;
        @media screen and (max-width: 500px) {
          width: 275px;
        }
        .title-text {
          text-align: center;
          font-size: 18px;
          font-weight: 600;
          border-bottom: 1px solid black;
          margin-bottom: 20px;
        }
        .cart-preview {
          padding: 10px;
          .cart-content {
            gap: 10px;
            display: flex;
            .preview-logo {
              width: 25px;
              height: 25px;
            }
            .cart-text-data {
              width: 250px;
              @media screen and (max-width: 500px) {
                width: 175px;
              }
              .cart-title {
                font-size: 18px;
                margin-top: -2px;
                word-wrap: break-word;
                margin: 0 auto;
              }
              .cart-description {
                font-size: 13px;
                line-height: 1.3;
                word-wrap: break-word;
                margin: 0 auto;
              }
              .info-btn {
                cursor: pointer;
              }
            }
          }
          .cart-footer {
            justify-content: center;
            background: #e5e5e5;
            border-radius: 24px;
            display: flex;
            align-items: center;
            padding: 5px 5px 5px 5px;
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 12px;
            color: #000;
            width: 100%;
            max-width: 300px;
            margin: 5px 0 0 0;
            gap: 5px;
            .preview-logo {
              width: 25px;
              height: 25px;
            }
          }
        }

        .tab-preview {
          background: #fafafa;
          margin-top: 20px;
          border-radius: 50px;
          padding: 25px;
          .header {
            display: flex;
            justify-content: space-between;
            margin-bottom: 5px;
            .header-title {
              font-size: 16px;
              line-height: 1;
              color: #000;
            }
            .powered-by {
              font-size: 12px;
              line-height: 1;
              color: #000;
            }
          }

          .tab-content {
            display: flex;
            flex-flow: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            .tab-title {
              margin: 24px 0 10px;
              font-size: 18px;
              font-weight: 800;
              line-height: 1;
              letter-spacing: 0;
              max-width: 250px;
              word-wrap: break-word;
            }
            .tab-description {
              font-size: 14px;
              color: #333;
              width: 100%;
              max-width: 216px;
              margin-bottom: 25px;
              line-height: 1.3;
              letter-spacing: 0;
              max-width: 250px;
              word-wrap: break-word;
            }
            .term-title {
              font-size: 16px;
              font-weight: 800;
              line-height: 1;
              margin: 0;
              color: #00a3b9;
              letter-spacing: 0;
              margin: 20px 0;
            }
            .term-description {
              font-size: 10px;
              color: #8b8b8b;
              width: 100%;
              line-height: 1.4;
            }
            .term-footer {
              font-size: 10px;
              color: #8b8b8b;
              width: 100%;
              line-height: 1.4;

              ul {
                padding: 0;
                border: 0;
                display: flex;
                justify-content: space-between;
                gap: 8px;
                width: 100%;
                max-width: 280px;
                margin: 0 auto;
                align-items: center;
                list-style: none;
                li {
                  padding: 0;
                  font-size: 10px;
                  line-height: 1;
                  color: #8b8b8b;
                  letter-spacing: 0;
                  a {
                    padding: 0;
                    font-size: 10px;
                    line-height: 1;
                    text-decoration: none;
                    color: #8b8b8b;
                    letter-spacing: 0;
                  }
                }
              }
            }
          }
          .ant-tabs-tab.ant-tabs-tab-active {
            background-color: black;
            padding: 0px 20px;
            border-radius: 35%;
          }
          .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
            display: none;
          }
          .ant-tabs-nav-wrap {
            padding: 17px;
            border-bottom: 1px solid $light_gray;
            border-top: 1px solid $light_gray;
          }
        }
      }
      @media screen and (max-width: 1100px) {
        width: 100%;
      }
    }
  }
}

.childConfig-container {
  .general-styleContainer {
    box-shadow: $box-shadow;
    padding: 20px 20px 0px 30px;
    margin: 50px auto 0px auto;
    border-radius: 10px;
    width: 100%;
  }

  .general-styleContainer {
    .firstChildContainer {
      .ant-form-item {
        max-width: 900px;
        width: 100%;
        .ant-form-item-row {
          flex-flow: row;
          @media screen and (max-width: 768px) {
            flex-direction: column;
          }
          .ant-form-item-label {
            @media screen and (max-width: 768px) {
              text-align: start;
              flex: 0 0 100%;
              max-width: 100%;
            }
          }
          .ant-form-item-control {
            @media screen and (max-width: 768px) {
              flex: 0 0 100%;
              max-width: 100%;
            }
          }
        }
        .ant-col-4 {
          flex: 0 0 200px;
          max-width: 200px;
          @media screen and (max-width: 768px) {
            flex: 0 0 auto;
            max-width: max-content;
          }
        }
        .ant-col-6 {
          flex: 0 0 calc(100% - 200px);
          max-width: calc(100% - 200px);
          @media screen and (max-width: 768px) {
            flex: 0 0 100%;
            max-width: 100%;
          }
        }
        .ant-form-item-control {
          .ant-form-item-control-input {
            .ant-form-item-control-input-content {
              // display: flex !important;
              // align-items: center !important;
              .password-field {
                display: flex !important;
                flex-direction: row !important;
                align-items: center !important;
              }
              .ant-row {
                flex-flow: nowrap;
                .ant-col {
                  flex-basis: auto;
                  max-width: max-content;
                }
              }
            }
          }
        }
      }
      width: 100%;
      .ant-input-textarea-affix-wrapper {
        margin-bottom: 20px;
      }
      .save-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 20px 0;
        max-width: 900px;
        width: 100%;
      }
      .livePreview {
        width: 50%;
        height: 750px;
        background-color: $light-white;
        display: flex;
        justify-content: center;
        border-radius: 20px;
        margin-top: 130px;
        padding-top: 20px;
      }

      .livePreview {
        .imgOfScreen {
          position: relative;
          z-index: 3;
        }
        .live-Text {
          font-size: 10px;
        }

        .imgContentDiv {
          height: 450px;
          position: absolute;
          top: 122px;
          left: 25px;
          width: 245px;
          z-index: 2;
        }

        .underScreen {
          height: 450px;
          position: absolute;
          top: 25px;
          left: 25px;
          width: 245px;
          z-index: 2;
        }

        .shipping-optimal {
          position: absolute;
          z-index: 1;
          top: 432px;
          background-color: $primary-black;
          text-align: center;
          width: 244px;
          min-height: 65px;
          left: 27px;
          z-index: 4;
          span {
            font-size: 13px;
            color: $primary-white;
            text-align: center;
          }
        }
        .shipping-optical2 {
          top: 72px;
          background-color: $primary-black;
          text-align: center;
          width: 244px;
          min-height: 65px;
          left: 27px;
          z-index: 4;
          position: absolute;
          z-index: 1;
        }

        .mobilDesktopContainer {
          width: 100%;
          max-width: 600px;
          margin: 0 auto;
          display: flex;
          justify-content: center;
          .mobile {
            background-color: $primary-black;
            color: $primary-white;
            padding: 5px 20px;
            border-radius: 30px;
            font-size: 10px;
          }
          .desktopText {
            background-color: $primary-black;
            color: $primary-white;
            padding: 5px 20px;
            border-radius: 30px;
            font-size: 10px;
          }
          .setWindow {
            max-width: 300px;
            width: 100%;
            margin: 0 auto;
          }
          .mobile-screenDiv {
            max-width: 450px;
            width: 100%;
            display: flex;
            justify-content: center;
          }

          .desktop {
            width: 600px;
            display: flex;
            justify-content: center;
          }
        }
      }

      .desktopSit-Container {
        display: flex;
        justify-content: center;
        align-items: center;

        .setBottomPartDiv {
          width: 100%;
          background-color: $primary-black;
          padding: 8px 0px;
          width: 599px;
          color: $primary-white;
          position: absolute;
          top: 367px;
          text-align: center;

          .sc-fudrna {
            position: relative;
          }
        }

        .bottomPartset {
          top: 35px;
          color: $primary-white;
          width: 599px;
          position: absolute;
          background-color: $primary-black;
          text-align: center;
          padding: 8px 0px;
        }
      }

      .ant-tabs-nav-wrap {
        width: 400px;
        span {
          font-family: $font-family;
        }
      }
      .ant-color-picker-trigger {
        border: none !important;
      }
      .ant-color-picker-color-block {
        width: 35px !important;
        min-width: 35px !important;
        height: 35px !important;
        border-radius: 35px !important;
      }
      .is-active {
        border-bottom: 1px solid $light_gray;
        margin-bottom: 15px;
        .switch-container {
          display: flex;
          .active-text {
            margin-left: 15px;
            font-size: 16px;
          }
        }
        .active-info {
          font-size: 12px;
          margin-bottom: 15px;
        }
      }
    }
  }

  .generalStyleSetting {
    width: 600px;
  }

  .generalStyleSetting p {
    font-size: 12px;
    margin: 0;
  }

  .generalStyleSetting hr {
    width: 600px;
    color: $light_gray;
  }

  .flexGeneral {
    .SpendingTitle-Container p {
      font-size: 19px;
      font-weight: bold;
    }

    .input-text {
      position: relative;
      width: 150px;
      padding: 9px 25px;
      background: none;
      border-radius: 9px;
      font-size: 1rem;
      border-radius: 1px solid $primary-black;
      margin-top: 5px;
    }

    .spendingDepartment {
      display: flex;
      width: 320px;
      justify-content: space-between;
      @media screen and (max-width: 481px) {
        flex-wrap: wrap;
        gap: 15px;
        .inputFieldsContainer {
          width: 100%;
          display: flex;
          gap: 15px;
          align-items: center;
        }
        .currencyContainer {
          .label-text {
            margin-right: 15px;
          }
        }
      }
      @media screen and (max-width: 391px) {
        flex-direction: column;
        align-items: flex-start;
        margin-top: 15px;
      }
    }

    .currencyContainer {
      .ant-select-arrow {
        padding: 10px;
      }
      .ant-space-item {
        margin-top: 5px;
      }
    }
    .switchContainer {
      display: flex;
      gap: 20px;
      .activeSlide {
        font-size: 16px;
      }
    }
  }

  .shippingOfferContainer {
    padding: 20px 0;
  }

  .container {
    display: block;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .container p {
    font-size: 14px;
  }

  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    border: 1px solid $primary-black;
  }

  .container:hover input ~ .checkmark {
    background-color: $primary-white;
  }

  .container input:checked ~ .checkmark {
    background-color: $primary-black;
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  .container input:checked ~ .checkmark:after {
    display: block;
  }

  .container .checkmark:after {
    left: 6px;
    top: 2px;
    width: 5px;
    height: 9px;
    border: solid $primary-white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .styleContentSet-Container {
    display: flex;
    max-width: 355px;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    .Banner-Background {
      position: relative;
      max-width: 250px;
      width: 100%;
      background: none;
      border-radius: 7px;
    }
    .backColor-setter {
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: 300px;
      width: 100%;
      margin-top: 5px;
      gap: 5px;
    }

    .colorPeckerContainer {
      width: 38px;
      height: 36px;
      box-shadow: $box-shadow;
      border-radius: 50%;
      border: 3px solid rgb(204, 204, 204);
    }
    .colorBtn {
      border-radius: 50%;
      height: 100%;
      width: 100%;
    }
    .colorPecker {
      height: 100%;
      width: 100%;
      border: none;
      outline: none;
    }
  }
  .fontSetContainer {
    position: relative;
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 272px;
    .select {
      width: 300px;
      padding: 23px 0px 5px 15px;
      border-radius: 15px;
    }
    .select option {
      padding-top: 10px;
    }
    .ant-select-arrow {
      padding: 10px;
    }

    .colorFontContainer {
      display: flex;
      max-width: 305px;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      margin-top: 15px;
      .fontColorInput {
        position: relative;
        max-width: 250px;
        width: 100%;
        border-radius: 7px;
      }
      .colorProtect {
        display: flex;
        flex-direction: column;
      }
      .colorFullFont {
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 300px;
        width: 100%;
        margin-top: 5px;
        gap: 5px;
      }
      .fontColorPicker {
        height: 100%;
        width: 100%;
        border: none;
        outline: none;
        border-radius: 50%;
      }
      .fontColorPickerContainer {
        width: 38px;
        height: 36px;
        min-width: 35px;
        border-radius: 50%;
        overflow: hidden;
        box-shadow: $box-shadow;
        border: 3px solid rgb(204, 204, 204);
        .fontBtn {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }
    }
  }
}

.location-Container {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100px;
  margin-bottom: 22px;
  .LocationContainer span {
    font-weight: bold;
    font-size: 16px;
    color: $dark_gray;
  }
  .location-option {
    display: flex;
    flex-direction: column;
    gap: 13px;
  }
}

.shippingOfferContainer {
  .offerContainer p {
    font-weight: bold;
    font-size: 16px;
    color: $dark_gray;
  }
  .someTextContainer p {
    font-size: 12px;
    color: $dark_gray;
  }
  .offer-container {
    padding: 0 0 5px 0;
    .radio-button {
      width: 100%;
      padding: 10px 0;
    }
  }
}
.Stickiness-Container {
  display: flex;
  flex-direction: column;
  height: 100px;
  justify-content: space-between;
  margin-bottom: 22px;
  .StickinessContainer p {
    font-weight: bold;
    font-size: 16px;
    color: $dark_gray;
  }

  .someTextContainer {
    .someText-content {
      font-size: 8px;
    }
  }
  .stickyContainerDiv {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}

.Close-Button-container {
  display: flex;
  flex-direction: column;
  height: 70px;
  justify-content: space-between;
  .titleCloseDiv p {
    font-weight: bold;
    font-size: 16px;
    color: $dark_gray;
  }
}

.SpendingTargetContainer {
  display: flex;
  flex-direction: column;
  // height: 113px;
  justify-content: space-between;
  margin-bottom: 22px;
  gap: 10px;
  .SpendingTitle-Container span {
    font-weight: bold;
    font-size: 16px;
    color: $dark_gray;
  }
}

@media (max-width: 1598px) {
  .childConfig-container {
    .general-styleContainer {
      .firstChildContainer {
        justify-content: normal;

        width: 100%;
        .livePreview {
          width: 587px;
          .mobilDesktopContainer .desktop {
            width: 591px;
          }
        }
        .generalStyleSetting {
          width: 93%;
        }
        .generalStyleSetting hr {
          width: 100%;
        }
        .desktopSit-Container .bottomPartset {
          top: 32px;
        }
      }
    }
  }
}

@media (max-width: 1580px) {
  .childConfig-container {
    .general-styleContainer {
      .firstChildContainer {
        justify-content: normal;
        width: 100%;
        .livePreview {
          width: 587px;

          .mobilDesktopContainer .desktop {
            width: 591px;
          }
        }
        .generalStyleSetting {
          width: 93%;
        }
        .generalStyleSetting hr {
          width: 100%;
        }
        .desktopSit-Container .bottomPartset {
          top: 32px;
        }
      }
    }
  }
}

@media (max-width: 1440px) {
  .childConfig-container {
    .general-styleContainer {
      .firstChildContainer {
        justify-content: normal;

        width: 100%;
        .livePreview {
          width: 587px;
          background-color: $primary-white;
          .mobilDesktopContainer .desktop {
            width: 591px;
          }
        }
        .generalStyleSetting {
          width: 79%;
        }
        .generalStyleSetting hr {
          width: 100%;
        }
        .desktopSit-Container .bottomPartset {
          top: 32px;
        }
        .desktopSit-Container .setBottomPartDiv {
          width: 587px;
          top: 357px;
        }

        .fontSetContainer {
          max-width: 479px;
          width: 100%;
        }
      }
    }
  }
}

@media (max-width: 1400px) and (min-width: 1300px) {
  .childConfig-container {
    .general-styleContainer {
      .firstChildContainer {
        justify-content: normal;

        width: 100%;
        .livePreview {
          width: 587px;
          background-color: $primary-white;
          .mobilDesktopContainer .desktop {
            width: 591px;
          }
        }
        .generalStyleSetting {
          width: 89%;
        }
        .generalStyleSetting hr {
          width: 100%;
        }
        .desktopSit-Container .bottomPartset {
          top: 32px;
        }
      }
    }
  }
}
@media (max-width: 1346px) and (min-width: 1200px) {
  .childConfig-container {
    .general-styleContainer {
      .firstChildContainer {
        justify-content: normal;

        width: 100%;
        .livePreview {
          width: 600px;
          background-color: $primary-white;
          .mobilDesktopContainer .desktop {
            width: 500px;
          }
        }
        .flexGeneral {
          width: 240px;
          .generalStyleSetting {
            width: 100%;
          }
        }
        .generalStyleSetting hr {
          width: 100%;
        }
        .desktopSit-Container .bottomPartset {
          top: 32px;
          width: 507px;
        }

        .desktopSit-Container .setBottomPartDiv {
          top: 306px;
          width: 507px;
        }
      }
    }
  }
}

@media (max-width: 1202px) {
  .childConfig-container {
    .general-styleContainer {
      .firstChildContainer {
        flex-direction: column;

        width: 100%;
        .livePreview {
          width: 798px;
          background-color: $primary-white;
        }
      }
    }
  }
}
@media (max-width: 1025px) {
  .childConfig-container {
    .general-styleContainer {
      .firstChildContainer {
        flex-direction: column;
        width: 100%;
        .generalstyaleSetting {
          width: 100%;
        }
        .livePreview {
          width: 100%;
          background-color: $primary-white;
          margin-top: 100px;
        }
      }
    }
  }
  .configBar_main {
    flex-direction: column;
  }
}

@media (max-width: 768px) {
  .childConfig-container {
    .general-styleContainer {
      .firstChildContainer {
        flex-direction: column;
        width: 100%;
        .livePreview {
          width: 100%;
          background-color: $primary-white;
          .sc-fudrna {
            .desktop {
              width: 346px;
            }
          }
        }

        .desktopSit-Container .setBottomPartDiv {
          top: 212px;
          width: 350px;
        }
        .desktopSit-Container .bottomPartset {
          top: 20px;
          width: 350px;
          background-color: red;
        }
      }
    }
  }
}

@media (min-width: 320px) and (max-width: 459px) {
  .onBoard-main .onBoard-container {
    padding: 15px;
  }
  .childConfig-container {
    .styleContentSet-Container {
      width: 199px;
      .backColor-setter {
        width: 193px;
      }
      .Banner-Background {
        width: 145px;
      }
    }
    .fontSetContainer {
      .selectFontFamily {
        .ant-select {
          width: 180px;
          .ant-select-selector {
            width: 192px;
          }
        }
      }
      .colorFontContainer {
        .fontColorInput {
          width: 144px;
        }
        .colorFullFont {
          width: 191px;
        }
      }
    }
    .flexGeneral {
      .SpendingTargetContainer {
        .spendingDepartment {
          flex-direction: column;
          gap: 15px;
          .currencyContainer {
            .label-text {
              display: block;
            }
          }
          .inputFieldsContainer {
            .label-text {
              display: block;
            }
          }
        }
      }
    }
    .general-styleContainer {
      padding: 20px;
      .firstChildContainer {
        width: 100%;
        .ant-tabs-nav-wrap span {
          font-size: 13px;
        }
        .ant-tabs-nav-wrap {
          display: flex;
          align-items: center;
        }
        .generalStyleSetting {
          width: 100%;
        }
        .desktopSit-Container {
          .setDesktopContainer {
            width: 220px;
          }
          .setBottomPartDiv {
            width: 217px;
            top: 135px;
          }
          .bottomPartset {
            width: 218px;
          }
        }
        .livePreview {
          width: 100%;
          height: 552px;
          background-color: $primary-white;
          margin-top: 41px;
          .imgContentDiv {
            height: 322px;
            top: 107px;
            left: 29px;
            width: 240px;
          }
          .shipping-optimal {
            width: 190px;
            top: 322px;
            left: 55px;
          }
          .shipping-optical2 {
            z-index: 4;
            width: 190px;
            top: 41px;
            left: 55px;
          }
          .underScreen {
            width: 200px;
            left: 49px;
            top: 15px;
          }
          .mobilDesktopContainer {
            .setWindow {
              .ant-tabs-nav-wrap {
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }
            .ant-tabs-top > .ant-tabs-nav::before {
              border-bottom: 0px solid white;
            }
            .ant-tabs-tab {
              margin: 0 0 0 3px;
            }
            .mobile {
              padding: 5px 13px;
            }
            .desktopText {
              padding: 5px 13px;
            }
            .mobile-screenDiv {
              width: 89%;
            }
            .BodyOfScreen {
              width: 246px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
          .sc-fudrna {
            .desktop {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

//Order detail

.order-detail-main {
  display: flex;
  min-height: 100vh;
  background-color: $theme_color;
  align-items: center;
  padding: 20px 20px 20px 0;
  flex-direction: row;
  @media only screen and (max-width: 1124px) {
    padding: 20px;
  }
  .order-detail-container {
    display: flex;
    min-height: 94vh;
    background-color: $primary-white;
    border-radius: 20px;
    width: -webkit-fill-available;
    flex-direction: column;
    padding: 20px;
    flex: 0 0 100%;
    max-width: 100%;
    .order-detail-header {
      display: flex;
      align-items: flex-start;
      .arrow-box {
        width: max-content;
        padding: 20px;
        background-color: #fff;
        margin-bottom: 20px;
        border-radius: 10px;
        border: none;
        transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        background-image: none;
        overflow: auto;
        box-shadow: rgba(145, 158, 171, 0.18) 0px 0px 2px 0px,
          rgba(145, 158, 171, 0.18) 0px 16px 32px -4px;
      }
      .order-detail-header-content {
        align-items: center;
        margin-left: 20px;
        .header-content-title {
          font-size: 13px;
          font-family: $font-family;
          font-weight: 400;
          margin-bottom: 9px;
        }
        .header-content-heading {
          font-size: 20px;
          margin: 0;
          font-family: $font-family;
          font-weight: 600;
        }
        @media (max-width: 368px) {
          .header-content-heading {
            font-size: 16px;
          }
        }
      }
    }

    .order-content-detail {
      width: 100%;
      padding: 20px;
      background-color: #fff;
      margin-bottom: 20px;
      border-radius: 10px;
      transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      background-image: none;
      overflow: auto;
      box-shadow: rgba(145, 158, 171, 0.18) 0px 0px 2px 0px,
        rgba(145, 158, 171, 0.18) 0px 16px 32px -4px;
      .order-title-head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 20px;
        .order-header {
          display: flex;
          align-items: center;
          gap: 20px;
          .order-content-title {
            font-size: 30px;
            font-family: $font-family;
            font-weight: 600;
            margin: 0px;
          }
          @media (max-width: 368px) {
            .order-content-title {
              font-size: 24px;
            }
          }
        }
        .order-merchant-name {
          font-size: 24px;
          font-family: $font-family;
          font-weight: 500;
          margin: 0px;
        }
        .status {
          font-size: 12px;
          border-radius: 7px !important;
          padding: 6px 8px;
          background-color: $theme_color;
          max-width: max-content;
        }
      }

      .order-status {
        display: flex;
        .order-status-placed {
          display: flex;
          background-color: #f2f2f2;
          border-radius: 7px !important;
          padding: 12px 8px;
          margin: 5px 20px 0px 0px;
          .ordrer-title {
            color: #343434;
            font-size: 14px;
            font-family: $font-family;
            font-weight: 600;
          }
          .ordrer-date {
            color: #343434;
            font-size: 14px;
            font-family: $font-family;
            font-weight: 400;
          }
        }
        @media (max-width: 390px) {
          .order-status-placed {
            flex-direction: column;
          }
        }
      }
      @media (max-width: 846px) {
        .order-status {
          flex-direction: column;
        }
      }

      @media (max-width: 645px) {
        .order-status {
          display: block;
        }
      }
      @media (max-width: 396px) {
        .order-status-placed {
          display: block;
        }
      }
    }
    .orde-info-container {
      box-sizing: border-box;
      display: flex;
      flex-flow: row wrap;
      width: 100%;
      justify-content: space-between;
      margin-top: 25px;
      .order-detail-left {
        flex-basis: calc(66.6667% - 10px);
        flex-grow: 0;
        max-width: calc(66.6667% - 10px);
        .order-detail-top {
          padding: 20px;
          background-color: #fff;
          margin-bottom: 20px;
          border-radius: 10px;
          transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
          overflow: auto;
          box-shadow: rgba(145, 158, 171, 0.18) 0px 0px 2px 0px,
            rgba(145, 158, 171, 0.18) 0px 16px 32px -4px;
          .order-list {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 20px 10px;
            .order-list-left {
              display: flex;
              flex-direction: row;
              align-items: center;
              gap: 10px;
              width: 40%;
              img {
                width: 50px;
                height: auto;
                border-radius: 5px;
              }
              .order-list-content {
                display: flex;
                flex-direction: column;
                .product-name {
                  color: #767676;
                  font-family: $font-family;
                  font-weight: 500;
                  font-size: 16px;
                }
              }
            }
            @media (max-width: 496px) {
              .order-list-left {
                flex-direction: column;
                align-items: flex-start;
              }
              .order-list-center,
              .order-list-right {
                margin-left: 0px;
                span {
                  font-size: 14x;
                }
              }
            }
            .order-list-center,
            .order-list-right {
              span {
                font-family: $font-family;
                color: #767676;
                font-weight: 500;
                font-size: 16px;
                width: 20%;
              }
            }
          }
          @media (max-width: 968px) {
            .order-list {
              flex-direction: column;
              align-items: flex-start;
              .order-list-left .order-list-content .product-name {
                font-size: 16px;
              }
              .order-list-left {
                width: 100%;
              }
              .order-list-center,
              .order-list-right {
                width: 100%;
                margin-left: 58px;
                span {
                  font-size: 14x;
                }
              }
            }
          }
          @media (max-width: 496px) {
            .order-list .order-list-left .order-list-content .product-name {
              font-size: 12px;
            }
            .order-list .order-list-center,
            .order-list .order-list-right {
              margin-left: 0px;
              span {
                font-size: 14px;
              }
            }
          }
          .btn-bottom {
            margin-top: 20px;
            display: flex;
            justify-content: flex-end;
            border-top: 1px solid #d4d4d4;

            .btn-container {
              padding-top: 20px;

              .Shipping-btn {
                background-color: #383838;
                padding: 12px;
                border-radius: 7px !important;
                border: none;

                .Shipping-btn-text {
                  color: $primary-white;
                  font-size: 12px;
                }
              }
            }
          }
        }
        .order-detail-bottom {
          background-color: #fff;
          margin-bottom: 20px;
          border-radius: 10px;
          transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
          overflow: auto;
          box-shadow: rgba(145, 158, 171, 0.18) 0px 0px 2px 0px,
            rgba(145, 158, 171, 0.18) 0px 16px 32px -4px;
          .paid-tag {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 5px;
            padding: 0px 10px;
            background-color: #e4ffe9;
            border-radius: 12;
            margin-top: 10px;
            width: max-content;
            height: 34px;
            .paid-text {
              font-family: $font-family;
              text-transform: capitalize;
              font-weight: 500;
              font-size: 16px;
              color: #00c51f;
            }
          }
          .paid-item {
            display: flex;
            justify-content: space-between;
            padding: 20px 0px;
            padding: 20px;
            span {
              color: #767676;
              font-family: $font-family;
              font-weight: 500;
              font-size: 16px;
            }
            @media (max-width: 390px) {
              span {
                font-size: 14px;
              }
            }
            @media (max-width: 368px) {
              span {
                font-size: 12px;
              }
            }
          }
          .paid-item-total {
            display: flex;
            border-top: 1px solid #d4d4d4;
            justify-content: space-between;
            padding-top: 10px;
            margin: 20px;
            span {
              color: #767676;
              font-family: $font-family;
              font-weight: 500;
              font-size: 16px;
            }
            @media (max-width: 390px) {
              span {
                font-size: 14px;
              }
            }
            @media (max-width: 368px) {
              span {
                font-size: 12px;
              }
            }
          }
          .paid-item-paid {
            display: flex;
            justify-content: space-between;
            background-color: #f2f2f2;
            padding: 20px;
            border-top: 1px solid #d4d4d4;
            span {
              color: #767676;
              font-family: $font-family;
              font-weight: 500;
              font-size: 16px;
            }
            @media (max-width: 390px) {
              span {
                font-size: 14px;
              }
            }
            @media (max-width: 368px) {
              span {
                font-size: 12px;
              }
            }
          }
        }
      }
      .order-detail-right {
        flex-basis: calc(33.3333% - 10px);
        flex-grow: 0;
        max-width: calc(33.3333% - 10px);
        .order-detail-top {
          padding: 20px;
          background-color: #fff;
          margin-bottom: 20px;
          border-radius: 10px;
          transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
          overflow: auto;
          box-shadow: rgba(145, 158, 171, 0.18) 0px 0px 2px 0px,
            rgba(145, 158, 171, 0.18) 0px 16px 32px -4px;
          .customer-details {
            margin: 0px;
            color: #343434;
            font-family: $font-family;
            font-weight: 700;
            font-size: 20px;
          }
          @media (max-width: 368px) {
            .customer-details {
              font-size: 16px;
            }
          }
          .edit-name {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 10px;
            padding: 20px 0px;
            border-bottom: 1px solid #c7c7c7;
            span {
              font-family: $font-family;
              font-weight: 400;
              font-size: 14px;
              color: #278aff;
            }
            .edit-mail {
              font-family: $font-family;
              font-weight: 400;
              font-size: 14px;
              color: #278aff;
            }
            @media (max-width: 368px) {
              span {
                font-size: 12px;
              }
            }
          }
          .contact-info {
            display: flex;
            gap: 10px;
            .contact-title {
              font-family: $font-family;
              font-weight: 700;
              font-size: 16px;
            }
            @media (max-width: 368px) {
              .contact-title {
                font-size: 14px;
              }
            }
          }
          .shipping-address {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 10px;
            .address {
              font-family: $font-family;
              font-weight: 400;
              font-size: 14px;
              width: 60%;
              color: #5c5c5c;
            }
          }
        }

        .order-detail-bottom {
          padding: 12px;
          background-color: #fff;
          margin-bottom: 20px;
          border-radius: 10px;
          transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
          overflow: auto;
          box-shadow: rgba(145, 158, 171, 0.18) 0px 0px 2px 0px,
            rgba(145, 158, 171, 0.18) 0px 16px 32px -4px;
          .tag-title {
            color: #343434;
            font-family: $font-family;
            font-weight: 700;
            font-size: 20px;
            margin: 0px;
          }
          .tags {
            display: flex;
            flex-wrap: wrap;
            .tag {
              background-color: #efefef;
              border-radius: 28px;
              color: #555;
              font-family: Maison Nue Ext, sans-serif;
              font-size: 12px;
              font-weight: 700;
              margin: 4px;
              padding: 6px 12px;
            }
          }
        }
      }
    }
    @media (max-width: 1390px) {
      .orde-info-container {
        display: block;
        .order-detail-left,
        .order-detail-right {
          max-width: 100%;
        }
      }
    }
  }
}

//onboarding

.onboarding-main {
  min-height: 100vh;
  background-color: $primary-white;
  display: flex;
  flex-direction: column;
  .onboarding-header {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $theme_color;
    padding: 15px;
    @media only screen and (max-width: 991px) {
      padding: 10px;
    }
    @media only screen and (max-width: 767px) {
      padding: 7px;
    }
    .logo svg {
      width: 110px;
      height: 140px;
      display: block;
      @media only screen and (max-width: 991px) {
        width: 100px;
        height: 130px;
      }
      @media only screen and (max-width: 767px) {
        width: 90px;
        height: 120px;
      }
    }
  }
  .onboarding-main-section {
    padding: 90px 15px;
    margin: 0 auto;
    max-width: 1150px;
    width: 100%;
    @media only screen and (max-width: 991px) {
      padding: 70px 15px;
    }
    @media only screen and (max-width: 767px) {
      padding: 50px 15px;
    }
    .onboarding-inner {
      display: flex;
      gap: 140px;
      @media only screen and (max-width: 1320px) {
        gap: 60px;
      }
      @media only screen and (max-width: 768px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      .left-side {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 400px;
        width: 100%;
        @media only screen and (max-width: 768px) {
          max-width: 100%;
        }
        .onboard-heading {
          margin: 40px 0 24px 0;
          @media only screen and (max-width: 1024px) {
            font-size: 16px;
          }
          @media only screen and (max-width: 768px) {
            font-size: 16px;
            margin: 35px 0 20px 0;
            text-align: center;
          }
          h1 {
            font-family: $font-family;
            font-size: 40px;
            font-weight: 600;
            margin: 0;
            padding: 0;
            color: $primary-black;
            line-height: 1.2;
          }
        }
        .onboard-title p {
          font-family: $font-family;
          font-size: 20px;
          font-weight: 500;
          line-height: 1.4;
          @media only screen and (max-width: 1024px) {
            font-size: 16px;
          }
          @media only screen and (max-width: 768px) {
            font-size: 16px;
            text-align: center;
          }
        }
      }
      .right-side {
        max-width: 611px;
        width: 100%;
        @media only screen and (max-width: 768px) {
          max-width: 100%;
        }
        .right-content p {
          font-size: 24px;
          font-family: $font-family;
          font-weight: 600;
          margin-bottom: 7px;
          line-height: 1.3;
          @media only screen and (max-width: 1024px) {
            font-size: 20px;
          }
          @media only screen and (max-width: 768px) {
            font-size: 20px;
            text-align: center;
          }
        }
        .calendly-div {
          padding: 24px;
          background-color: #ebebeb;
          margin: 10px 0;
          @media only screen and (max-width: 1024px) {
            padding: 20px;
          }
          @media only screen and (max-width: 768px) {
            padding: 18px;
          }
        }
        .btn-div {
          display: flex;
          justify-content: center;
          align-items: center;
          .primary_btn.btn {
            background-color: $primary-black;
            width: 273px;
            border-radius: 60px !important;
            color: $primary-white;
            border: 1px solid $primary-black;
            padding: 14px;
            cursor: pointer;
            &:hover {
              background-color: #464646;
              color: $primary-white;
            }
          }
        }
      }
    }
  }
}

// onBoarding Success Page
.onboarding-main {
  min-height: 100vh;
  background-color: $primary-white;
  display: flex;
  flex-direction: column;
  .onboarding-header {
    padding: 15px;
    @media only screen and (max-width: 991px) {
      padding: 10px;
    }
    @media only screen and (max-width: 767px) {
      padding: 7px;
    }
    .logo .logo-img {
      width: 110px;
      height: 140px;
      display: block;
      @media only screen and (max-width: 991px) {
        width: 100px;
        height: 130px;
      }
      @media only screen and (max-width: 767px) {
        width: 90px;
        height: 120px;
      }
    }
  }
  .onboarding-success-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 90px 15px;
    width: 100%;
    @media only screen and (max-width: 991px) {
      padding: 70px 15px;
    }
    @media only screen and (max-width: 767px) {
      padding: 50px 15px;
    }
    .inner {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      max-width: 620px;
      width: 100%;
      gap: 48px;
      @media only screen and (max-width: 576px) {
        width: 100%;
      }
      .success-img svg {
        height: 266px;
        width: 266px;
        display: block;
        @media only screen and (max-width: 991px) {
          height: 240px;
          width: 240px;
        }
        @media only screen and (max-width: 767px) {
          height: 210px;
          width: 210px;
        }
      }
      .success-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 24px;
        .success-heading h1 {
          text-align: center;
          font-size: 40px;
          font-family: $font-family;
          font-weight: 600;
          padding: 0;
          margin: 0;
          @media only screen and (max-width: 991px) {
            font-size: 36px;
          }
          @media only screen and (max-width: 767px) {
            font-size: 30px;
          }
        }
        .success-text p {
          font-size: 18px;
          font-weight: 400;
          text-align: center;
          @media only screen and (max-width: 767px) {
            font-size: 15px;
          }
        }
      }
    }
  }
}

.another-table-pagination {
  .ant-pagination {
    justify-content: flex-end !important;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
      "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    .ant-pagination-prev {
      display: flex !important;
      align-items: center !important;
      button {
        display: block !important;
        width: 100% !important;
        height: 100% !important;
        padding: 0 !important;
        font-size: 12px !important;
        text-align: center !important;
        background-color: transparent !important;
        border: 1px solid transparent !important;
        border-radius: 6px !important;
        outline: none !important;
        transition: all 0.2s !important;
        span {
          display: inline-block !important;
        }
      }
    }
    .ant-pagination-next {
      display: flex !important;
      align-items: center !important;
      button {
        display: block !important;
        width: 100% !important;
        height: 100% !important;
        padding: 0 !important;
        font-size: 12px !important;
        text-align: center !important;
        background-color: transparent !important;
        border: 1px solid transparent !important;
        border-radius: 6px !important;
        outline: none !important;
        transition: all 0.2s !important;
        span {
          display: inline-block !important;
        }
      }
    }
    .ant-pagination-next {
      color: $primary-black;
    }
    .ant-pagination-item {
      display: inline-block !important;
      min-width: 32px !important;
      height: 32px !important;
      margin-inline-end: 8px !important;
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
        "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
        "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      line-height: 30px !important;
      text-align: center !important;
      vertical-align: middle !important;
      list-style: none !important;
      background-color: transparent !important;
      border: 1px solid transparent !important;
      border-radius: 6px !important;
      outline: 0 !important;
      cursor: pointer !important;
      user-select: none !important;
      &.ant-pagination-item-active {
        font-weight: 600 !important;
        background-color: #ffffff !important;
        border-color: #1677ff !important;
        a {
          color: #1677ff !important;
        }
      }
      a {
        font-weight: 500;
        color: $primary-black;
      }
    }
    .ant-pagination-item-link {
      border-radius: 100px;
    }
  }
}

.ant-picker-preset-active {
  background-color: $theme_color;
  color: $primary-black;
}

.ant-picker-panel-layout div {
  display: flex;
  flex-direction: row-reverse;
  .ant-picker-footer {
    width: 150px;
    height: 309px;
    overflow: scroll;
    ul {
      li + li {
        margin-top: 5px !important;
      }
      :hover {
        background: $theme_color !important;
      }
      .ant-picker-preset-active {
        background-color: $theme_color;
        color: $primary-black;
      }
    }
  }
}
.ant-picker-dropdown .ant-picker-footer-extra {
  line-height: 25px !important;
}
